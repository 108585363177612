import * as actions from '../constants/actions';

export function loginRequest(email, password) {
  return {
    type: actions.LOGIN_REQUEST,
    payload: {
      email,
      password,
    },
  };
}

export function loginSucceed() {
  return {
    type: actions.LOGIN_REQUEST_SUCCEED,
    payload: {},
  };
}

export function loginFailed(err) {
  return {
    type: actions.LOGIN_REQUEST_FAILED,
    payload: {
      message: err.message,
      type: err.type,
    },
  };
}

export function unauthenticated(err) {
  return {
    type: actions.INVALID_AUTHENTICATION,
    payload: {
      message: err.message,
      type: err.type,
    },
  };
}
