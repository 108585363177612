import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import * as lockerUtils from '../../utils/lockerData';

export default function LockerPreview(props) {
  const {
    companyPreview, generalPreview, networkPreview, towersPreview, onEdit,
  } = props;
  const fill = [generalPreview, towersPreview, companyPreview, networkPreview];
  const handleEdit = (key) => () => onEdit(key);
  const liItems = lockerUtils.TABS.slice(0, 4).map((item, i) => (
    <li key={item.key}>
      <h4>{ item.title }</h4>
      <p>
        { fill[i] }
        <Button className="pull-right edit" variant="btn-link" onClick={handleEdit(item.key)}>
          Editar
        </Button>
      </p>
    </li>
  ));

  return (
    <ul id="locker-resume">
      { liItems }
    </ul>
  );
}

LockerPreview.propTypes = {
  generalPreview: PropTypes.string,
  networkPreview: PropTypes.string,
  towersPreview: PropTypes.string,
  companyPreview: PropTypes.string,
  onEdit: PropTypes.func,
};
