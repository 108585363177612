export const INVALID_EMAIL = 'Ingresa un correo válido';
export const REQUIRED_EMAIL = 'El correo es requerido';
export const REQUIRED_PASSWORD = 'La contraseña es requerida';

export const INVALID_GRANT = 'Credenciales inválidas';
export const INVALID_SCOPE = 'No tienes permisos para esta aplicación';
export const UNAUTHORIZED_REQUEST = 'Sesión expirada';
export const INVALID_TOKEN = 'Sesión expirada';

export const DEFAULT_ERROR = 'Ha ocurrido un error, intenta más tarde';

export const FAILED_DEPENDENCY = 'No se pudo realizar la petición';
export const FIRMWARE_ERROR = 'No se obtuvo respuesta del locker';
export const INVALID_BOX = 'La caja seleccionada tiene una reservación activa';
export const INVALID_TOWER = 'La torre seleccionada no ha sido inicializada';

export const OPERATION_REJECTED = 'No es posible hacer esta acción';
export const INVALID_ASSIGNMENT = 'No se ha podido satisfacer la asignación';
