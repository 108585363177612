import React from 'react';
import './App.scss';
import { Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Dashboard from './pages/Dashboard/Dashboard';
import Maintenance from './pages/Maintenance/Maintenance';
import LoginPage from './pages/Login/Login';
import LockersPage from './pages/Lockers/Lockers';
import NewLocker from './pages/Lockers/NewLocker';
import EditLocker from './pages/Lockers/EditLocker';
import LockerView from './pages/Lockers/LockerView';
import PrivateRoute from './components/PrivateRoute';
import Sidebar from './components/Layout/Aside';
import Logout from './components/Auth/Logout';
import * as routes from './constants/routes';

function App() {
  return (
    <Router>
      {/* main container */}
      <Row noGutters id="wrapper">
        {/* Render sidebar except on login */}
        <Route render={({ location }) => {
          if (location.pathname.startsWith(routes.LOGIN_PATH)) {
            return null;
          }

          return <Sidebar />;
        }}
        />
        {/* Content column */}
        <Col id="main-content">
          <Switch>
            <PrivateRoute
              exact
              path={routes.HOME_PATH}
              component={Dashboard}
            />
            <PrivateRoute
              path={routes.MAINTENANCE_PATH}
              component={Maintenance}
            />
            <PrivateRoute
              exact
              path={routes.LOCKERS_PATH}
              component={LockersPage}
            />
            <PrivateRoute
              path={routes.NEW_LOCKER_PATH}
              component={NewLocker}
            />
            <PrivateRoute
              path={routes.LOCKER_VIEW_PATH}
              component={LockerView}
            />
            <PrivateRoute
              path={routes.LOCKER_EDIT_PATH}
              component={EditLocker}
            />
            <Route
              path={routes.LOGOUT_PATH}
              component={Logout}
            />
            <Route path={routes.LOGIN_PATH} component={LoginPage} />
          </Switch>
        </Col>
      </Row>
    </Router>
  );
}

export default App;
