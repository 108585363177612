import React from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'rsuite';
import { subtractDays } from '../../utils/dates';

const { combine, before, afterToday } = DateRangePicker;

export default function RangePicker(props) {
  const { minDaysAgo, handleChange } = props;

  return (
    <DateRangePicker
      disabledDate={combine(before(subtractDays(minDaysAgo)), afterToday())}
      placeholder="Seleccionar rango"
      block
      showOneCalendar
      size="lg"
      placement="auto"
      ranges={[{
        label: 'Últimos 7 días',
        value: [subtractDays(7), new Date()],
      }, {
        label: 'Últimos 15 días',
        value: [subtractDays(15), new Date()],
      }]}
      onChange={handleChange}
    />
  );
}

RangePicker.propTypes = {
  minDaysAgo: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};
