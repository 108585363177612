import Tower from '../../utils/TowerConfigurator';

const ADD_TOWER = 'ADD_TOWER';
const REMOVE_TOWER = 'REMOVE_TOWER';
const SET_MAIN = 'SET_MAIN';
const MOVE_BOX = 'MOVE_BOX';
const UPDATE_COUNT = 'UPDATE_COUNT';
const RESET = 'RESET';

export const initialState = {
  towers: [],
  dirty: false,
  isValid: true,
  deleteTowers: [],
  hasMain: false,
};

export function init(towersDef) {
  const towers = towersDef.map(Tower.from);

  return {
    towers,
    dirty: false,
    isValid: true,
    deleteTowers: [],
    hasMain: towers.some((t) => t.isMain),
  };
}

export function addTower() {
  return {
    type: ADD_TOWER,
    payload: {},
  };
}

export function removeTower(id) {
  return {
    type: REMOVE_TOWER,
    payload: { id },
  };
}

export function setMainTower(id) {
  return {
    type: SET_MAIN,
    payload: { id },
  };
}

export function moveBox(towerId, target, dropIndex) {
  return {
    type: MOVE_BOX,
    payload: {
      id: towerId,
      targetedIndex: target.index,
      targetedBox: target.box,
      dropIndex,
    },
  };
}

export function updateCount(towerId, size, count) {
  return {
    type: UPDATE_COUNT,
    payload: {
      id: towerId,
      size,
      count,
    },
  };
}

export function reset(towers) {
  return {
    type: RESET,
    payload: {
      towers,
    },
  };
}

export function reducer(state, action) {
  const nextId = state.towers.length
    ? state.towers[state.towers.length - 1].id + 1
    : 1;

  switch (action.type) {
    case ADD_TOWER:
      return {
        towers: [
          ...state.towers,
          new Tower(nextId),
        ],
        dirty: true,
        hasMain: state.hasMain,
        deleteTowers: state.deleteTowers,
        isValid: false,
      };
    case REMOVE_TOWER:
      // eslint-disable-next-line no-case-declarations
      const towerToRemove = state.towers.find((t) => t.id === action.payload.id);

      return {
        towers: state.towers.filter((tower) => tower.id !== action.payload.id),
        deleteTowers: [...state.deleteTowers, towerToRemove.uuid],
        hasMain: towerToRemove.isMain ? false : state.hasMain,
        dirty: true,
        isValid: state.isValid,
      };
    case SET_MAIN:
      return {
        towers: [
          ...state.towers.map((tower) => {
            if (!tower.isMain && tower.id !== action.payload.id) {
              return tower;
            }

            return tower.toggleMain();
          }),
        ],
        deleteTowers: state.deleteTowers,
        hasMain: state.hasMain,
        dirty: state.dirty,
        isValid: state.isValid,
      };
    case MOVE_BOX:
      return {
        towers: state.towers.map((tower) => {
          if (tower.id !== action.payload.id) {
            return tower;
          }

          return tower.moveBox(
            action.payload.targetedIndex,
            action.payload.dropIndex,
            action.payload.targetedBox,
          );
        }),
        deleteTowers: state.deleteTowers,
        hasMain: state.hasMain,
        dirty: state.dirty,
        isValid: state.isValid,
      };
    case UPDATE_COUNT:
      // eslint-disable-next-line no-case-declarations
      const newTowers = state.towers.map((tower) => {
        if (tower.id !== action.payload.id) {
          return tower;
        }

        return tower.setCount(action.payload.size, action.payload.count);
      });

      return {
        towers: newTowers,
        deleteTowers: state.deleteTowers,
        hasMain: state.hasMain,
        dirty: state.dirty,
        isValid: newTowers.every((tower) => !tower.hasCapacityError()),
      };
    case RESET:
      return init(action.payload.towers);
    default:
      throw new Error();
  }
}
