import { applyMiddleware, createStore, compose } from 'redux';
import { createLogicMiddleware } from 'redux-logic';
import { createLogger } from 'redux-logger';
import allReducers from './reducers';
import allLogic from './logic/index';
import axios from './utils/api';

const logicMiddleware = createLogicMiddleware(allLogic, {
  axios,
});
const middlewares = [];

middlewares.push(logicMiddleware);

if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger());
}

const store = compose(applyMiddleware(...middlewares))(createStore)(allReducers);

export default store;
