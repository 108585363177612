export const LOGIN_PATH = '/login';
export const LOGOUT_PATH = '/logout';
export const HOME_PATH = '/';
export const MAINTENANCE_PATH = '/maintenance';
export const LOCKERS_PATH = '/lockers';
export const NEW_LOCKER_PATH = '/lockers/new';
export const BUILD_LOCKER_VIEW_PATH = (id) => `/lockers/${id}/view`;
export const LOCKER_VIEW_PATH = '/lockers/:id/view';
export const LOCKER_EDIT_PATH = '/lockers/:id/edit';
export const BUILD_LOCKER_EDIT_PATH = (id) => `/lockers/${id}/edit`;
