import React from 'react';
import './Aside.scss';
import { NavLink } from 'react-router-dom';
import {
  Nav, Col, Navbar,
} from 'react-bootstrap';
import { ReactComponent as BrandLogo } from '../../assets/images/logo--dark.svg';
import { ReactComponent as HomeLogo } from '../../assets/images/home.svg';
import { ReactComponent as Logout } from '../../assets/images/logout.svg';
import { ReactComponent as Maintenance } from '../../assets/images/maintenance.svg';
import { ReactComponent as LockersLogo } from '../../assets/images/lockers.svg';
import * as routes from '../../constants/routes';

export function Aside() {
  return (
    <Col xs={12} lg={3} xl={2} id="sidebar-menu" as="aside">
      <Navbar expand="lg" bg="transparent" variant="dark">
        <Navbar.Brand as="div">
          <BrandLogo />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav fill className="flex-column">
            <NavLink exact to={routes.HOME_PATH}>
              <HomeLogo />
              Inicio
            </NavLink>
            <NavLink to={routes.LOCKERS_PATH}>
              <LockersLogo />
              Lockers
            </NavLink>
            <NavLink exact to={routes.MAINTENANCE_PATH}>
              <Maintenance />
              Mantenimiento
            </NavLink>
            <NavLink exact to={routes.LOGOUT_PATH} className="mt-2">
              <Logout />
              Salir
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Col>
  );
}

export default Aside;
