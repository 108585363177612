export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_FAILED = 'LOGIN_REQUEST_FAILED';
export const LOGIN_REQUEST_SUCCEED = 'LOGIN_REQUEST_SUCCEED';
export const INVALID_AUTHENTICATION = 'INVALID_AUTHENTICATION';

// Validate saved token
export const VALIDATE_LOGIN_REQUEST = 'VALIDATE_LOGIN_REQUEST';
export const LOGIN_EXPIRED = 'LOGIN_EXPIRED';

// Locker operations
export const LOCKERS_REQUEST = 'LOCKERS_REQUEST';
export const LOCKERS_REQUEST_FAILED = 'LOCKERS_REQUEST_FAILED';
export const LOCKERS_REQUEST_SUCCEED = 'LOCKERS_REQUEST_SUCCEED';
export const LOCKER_REQUEST = 'LOCKER_REQUEST';
export const LOCKER_REQUEST_FAILED = 'LOCKER_REQUEST_FAILED';
export const LOCKER_REQUEST_SUCCEED = 'LOCKER_REQUEST_SUCCEED';
export const LOCKER_CREATE_REQUEST = 'LOCKER_CREATE_REQUEST';
export const LOCKER_CREATE_REQUEST_SUCCEED = 'LOCKER_CREATE_REQUEST_SUCCEED';
export const LOCKER_UPDATE_REQUEST = 'LOCKER_UPDATE_REQUEST';
export const LOCKER_UPDATE_REQUEST_SUCCEED = 'LOCKER_UPDATE_REQUEST_SUCCEED';
export const LOCKER_UPDATE_NETWORK_REQUEST = 'LOCKER_UPDATE_NETWORK_REQUEST';
export const LOCKER_UPDATE_NETWORK_REQUEST_SUCCEED = 'LOCKER_UPDATE_NETWORK_REQUEST_SUCCEED';
export const LOCKER_UPDATE_TOWERS_REQUEST = 'LOCKER_UPDATE_TOWERS_REQUEST';
export const LOCKER_UPDATE_TOWERS_REQUEST_SUCCEED = 'LOCKER_UPDATE_TOWERS_REQUEST_SUCCEED';
export const LOCKER_UPDATE_COMPANY_REQUEST = 'LOCKER_UPDATE_COMPANY_REQUEST';
export const LOCKER_UPDATE_COMPANY_REQUEST_SUCCEED = 'LOCKER_UPDATE_COMPANY_REQUEST_SUCCEED';

// Firmware operations
export const LOCKER_SYNC_REQUEST = 'LOCKER_SYNC_REQUEST';
export const LOCKER_SYNC_REQUEST_SUCCEEDED = 'LOCKER_SYNC_REQUEST_SUCCEEDED';
export const LOCKER_SYNC_TOWERS_REQUEST = 'LOCKER_SYNC_TOWERS_REQUEST';
export const LOCKER_SYNC_TOWERS_REQUEST_SUCCEEDED = 'LOCKER_SYNC_TOWERS_REQUEST_SUCCEEDED';
export const FIRMWARE_REQUEST = 'FIRMWARE_REQUEST';
export const FIRMWARE_REQUEST_FAILED = 'FIRMWARE_REQUEST_FAILED';
export const FIRMWARE_REQUEST_SUCCEED = 'FIRMWARE_REQUEST_SUCCEED';
