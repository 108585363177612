import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import PropTypes from 'prop-types';

export default function CustomPagination(props) {
  const { current, total, handleChange } = props;
  const pageItems = [];

  for (let page = 1; page <= total; page++) {
    pageItems.push(
      <Pagination.Item key={page} active={page === current} onClick={() => handleChange(page)}>
        {page}
      </Pagination.Item>,
    );
  }

  const isLast = current === total || total === 0;
  const isFirst = current === 1 || total === 0;

  if (total <= 1) {
    return null;
  }

  return (
    <Pagination>
      <Pagination.First disabled={isFirst} onClick={() => handleChange(1)} />
      <Pagination.Prev disabled={isFirst} onClick={() => handleChange(current - 1)} />
      { pageItems }
      <Pagination.Next disabled={isLast} onClick={() => handleChange(current + 1)} />
      <Pagination.Last disabled={isLast} onClick={() => handleChange(total)} />
    </Pagination>
  );
}

CustomPagination.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};
