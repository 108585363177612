import DoorForm from '../Forms/DoorForm';
import TowerForm from '../Forms/TowerForm';
import { ReactComponent as Success } from '../../assets/images/modal__success.svg';
import { ReactComponent as Forbidden } from '../../assets/images/modal__forbidden.svg';
import * as titles from '../../constants/titles';

export default {
  [titles.OPEN_BOX]: {
    title: titles.OPEN_BOX_TITLE,
    success: Success,
    Form: DoorForm,
  },
  [titles.OPEN_TOWER]: {
    title: titles.OPEN_TOWER_TITLE,
    success: Success,
    Form: TowerForm,
  },
  [titles.GET_BOX]: {
    title: titles.GET_BOX_TITLE,
    open: Success,
    closed: Forbidden,
    Form: DoorForm,
  },
  [titles.GET_IR]: {
    title: titles.GET_IR_TITLE,
    occupied: Forbidden,
    available: Success,
    Form: DoorForm,
  },
  [titles.RESET_TOWER]: {
    title: titles.RESET_TOWER_TITLE,
    success: Success,
    Form: TowerForm,
  },
  [titles.RESET_LOCKER]: {
    title: titles.RESET_LOCKER_TITLE,
    success: Success,
    Form: DoorForm,
  },
};
