import React from 'react';
import {
  Container, Col, Card, Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NewLocationsTable from '../../components/Locations/NewLocationsTable';
import * as titles from '../../constants/titles';

export function Lockers() {
  const list = useSelector((store) => store.lockers.list);
  const isLoading = useSelector((store) => store.lockers.isLoading);
  const locationsUnsynced = list.filter((loc) => !loc.internal_data?.rpi?.synced_at);

  return (
    <Container fluid>
      <Col>
        <h1>LOCKERS</h1>
        <Card className="col-4">
          <Card.Body>
            <Card.Title>
              <h2>{ titles.LOCKERS_CARD_TITLE }</h2>
            </Card.Title>
            <Card.Text>
              { titles.LOCKERS_CARD_TEXT }
            </Card.Text>
          </Card.Body>
          <Link
            to="/lockers/new"
            className="btn btn-primary btn-block"
          >
            { titles.LOCKERS_CARD_BUTTON }
          </Link>
        </Card>
        <Card className="col-6">
          <Card.Body className="text-center">
            <Card.Title className="text-left">
              <h2>{ titles.LOCKERS_REGISTRY_TITLE }</h2>
            </Card.Title>
            {
              isLoading
                ? <Spinner animation="border" variant="secondary" className="spinner--full" />
                : <NewLocationsTable results={locationsUnsynced} />
            }
          </Card.Body>
        </Card>
      </Col>
    </Container>
  );
}

export default Lockers;
