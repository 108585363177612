import initialState from './initialState';
import * as types from '../../constants/actions';
import * as errors from '../../constants/errors';

const firmwareReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOCKER_SYNC_REQUEST:
    case types.LOCKER_SYNC_TOWERS_REQUEST:
    case types.FIRMWARE_REQUEST:
      return {
        ...initialState,
        isLoading: true,
      };
    case types.LOCKER_SYNC_REQUEST_SUCCEEDED:
    case types.LOCKER_SYNC_TOWERS_REQUEST_SUCCEEDED:
    case types.FIRMWARE_REQUEST_SUCCEED:
      return {
        ...state,
        isError: false,
        isLoading: false,
        ...action.payload,
      };
    case types.FIRMWARE_REQUEST_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        message: errors[action.payload.type] || errors.DEFAULT_ERROR,
        errorData: action.payload.data,
      };
    case types.INVALID_AUTHENTICATION:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default firmwareReducer;
