import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';

function AuthNotification() {
  const [show, setShow] = useState(true);
  const isError = useSelector((store) => store.auth.isError);
  const message = useSelector((store) => store.auth.message);

  if (show && isError) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible className="alert-pop">
        <p>
          { message }
        </p>
      </Alert>
    );
  }

  return '';
}

export default AuthNotification;
