/* eslint-disable max-len */
import { createLogic } from 'redux-logic';
import * as types from '../constants/actions';
import * as endpoints from '../constants/api';
import * as actions from '../actions/lockers';
import { getServiceDays } from '../utils/serviceDays';
import { BUILD_LOCKER_VIEW_PATH } from '../constants/routes';
import handleError from './handleError';

function getLocationCompany(boxes) {
  if (boxes.length < 1) {
    return 'Sin asignar';
  }

  const companies = [];
  const defaultCompany = 'Pública';

  boxes.reduce((res, value) => {
    const name = value.company ? value.company.name : defaultCompany;

    if (!res[name]) {
      res[name] = {
        name,
        total: 0,
      };
      companies.push(res[name]);
    }

    res[name].total += value.total;
    return res;
  }, {});

  const company = companies.reduce((prev, current) => (prev.total > current.total ? prev : current), companies[0]);

  return company.name;
}

function getTotalBoxes(boxes) {
  return boxes.reduce((cnt, box) => cnt + box.total, 0);
}

const getAllLockersLogic = createLogic({
  type: types.LOCKERS_REQUEST,
  latest: true,
  processOptions: {
    successType: actions.lockersSucceed,
    failType: handleError(actions.lockersFailed),
  },
  async process({ axios }) {
    // eslint-disable-next-line camelcase
    const { locations } = await axios.get(endpoints.API_LOCKERS_PATH, {
      params: {
        // ...action.payload,
        page_size: 180,
        include_companies: true,
        include_status: true,
      },
    });
    const list = locations.map((result) => {
      const { boxes, node } = result;
      const location = {
        id: result.id,
        created_at: result.created_at,
        connected: node.connected,
        metadata: {
          version: 'unknown',
          model: 'unknown',
        },
        internal_name: result.internal_name,
        address: result.address,
        uuid: result.uuid,
        company: getLocationCompany(boxes),
        total_boxes: getTotalBoxes(boxes),
        internal_data: result.internal_data,
      };

      if (result.internal_data && result.internal_data.rpi && result.internal_data.rpi.version) {
        location.metadata.version = `v${result.internal_data.rpi.version}`;
      }

      if (result.internal_data && result.internal_data.rpi && result.internal_data.rpi.operating_model) {
        location.metadata.model = result.internal_data.rpi.operating_model.name;
      }

      return location;
    });

    return {
      list,
    };
  },
});

const getLockerLogic = createLogic({
  type: types.LOCKER_REQUEST,
  latest: true,
  processOptions: {
    successType: actions.lockerSucceed,
    failType: handleError(actions.lockerFailed),
  },
  async process({ axios, action }) {
    const { location } = await axios.get(endpoints.API_LOCKER_PATH + action.payload.id, {
      params: {
        include_status: action.payload.includeStatus,
      },
    });

    return location;
  },
});

const postLockerLogic = createLogic({
  type: types.LOCKER_CREATE_REQUEST,
  latest: true,
  processOptions: {
    successType: types.LOCKER_CREATE_REQUEST_SUCCEED,
    failType: handleError(actions.lockerCreateFailed),
  },
  async process({ axios, action }) {
    const {
      payload: {
        locationData, towers, companyAssignment, networkData, history,
      },
    } = action;
    const body = {
      coords: {
        longitude: locationData.coords.long,
        latitude: locationData.coords.lat,
      },
      address: {
        ...locationData.address,
        parking: locationData.parking,
        cross_streets: locationData.cross_streets,
        references: locationData.references,
      },
      phone: locationData.phone,
      hardware_version: locationData.hardware_version,
      company_host: locationData.company_host,
      internal_name: locationData.internal_name,
      service_days: getServiceDays(locationData),
      timezone: 'America/Chicago',
    };

    const { location } = await axios.post(endpoints.API_CREATE_LOCATION_PATH, body);

    try {
      if (towers.length > 0) {
        await axios.post(endpoints.API_LOCATION_TOWERS_PATH(location.id), {
          towers,
        });
      }

      await axios.put(endpoints.API_LOCATION_NETWORK_PATH(location.id), {
        ssid: networkData.ssid,
        psw: networkData.password,
        vpn_ip: networkData.vpn_ip,
      });
      await axios.put(endpoints.API_LOCATION_MODEL_PATH(location.id), {
        operating_model: companyAssignment.operating_model,
      });

      if (companyAssignment.network !== 'public') {
        const assignment = [];

        assignment.push({
          company_id: companyAssignment.company,
          size: 's',
          wanted: +companyAssignment.small,
        });
        assignment.push({
          company_id: companyAssignment.company,
          size: 'm',
          wanted: +companyAssignment.medium,
        });
        assignment.push({
          company_id: companyAssignment.company,
          size: 'l',
          wanted: +companyAssignment.large,
        });
        assignment.push({
          company_id: companyAssignment.company,
          size: 'xl',
          wanted: +companyAssignment.extraLarge,
        });

        await axios.put(endpoints.API_LOCATION_COMPANY_PATH(location.id), {
          assignment,
        });
      }
    } finally {
      history.replace(BUILD_LOCKER_VIEW_PATH(location.id));
    }
  },
});

const updateLockerLogic = createLogic({
  type: types.LOCKER_UPDATE_REQUEST,
  latest: true,
  processOptions: {
    failType: handleError(actions.lockerFailed),
    successType: actions.lockerUpdateSucceed,
  },
  async process({ axios, action }) {
    const {
      payload: { locationData, id },
    } = action;
    const body = {
      coords: {
        longitude: locationData.coords.long,
        latitude: locationData.coords.lat,
      },
      address: {
        ...locationData.address,
        parking: locationData.parking,
        cross_streets: locationData.cross_streets,
        references: locationData.references,
      },
      phone: locationData.phone,
      hardware_version: locationData.hardware_version,
      company_host: locationData.company_host,
      internal_name: locationData.internal_name,
      service_days: getServiceDays(locationData),
    };

    const { location } = await axios.put(endpoints.API_UPDATE_LOCATION_PATH(id), body);

    return location;
  },
});

const updateLockerNetworkLogic = createLogic({
  type: types.LOCKER_UPDATE_NETWORK_REQUEST,
  latest: true,
  processOptions: {
    failType: handleError(actions.lockerFailed),
    successType: types.LOCKER_UPDATE_NETWORK_REQUEST_SUCCEED,
  },
  async process({ axios, action }) {
    const {
      payload: { networkData, id },
    } = action;
    const res = await axios.put(endpoints.API_LOCATION_NETWORK_PATH(id), {
      ssid: networkData.ssid,
      psw: networkData.password,
      vpn_ip: networkData.vpn_ip,
    });

    return res.internal_data.network;
  },
});

const updateLockerTowersLogic = createLogic({
  type: types.LOCKER_UPDATE_TOWERS_REQUEST,
  latest: true,
  processOptions: {
    failType: handleError(actions.lockerFailed),
    successType: types.LOCKER_UPDATE_TOWERS_REQUEST_SUCCEED,
  },
  async process({ axios, action }) {
    const {
      payload: { newTowers, id, deletedTowers },
    } = action;

    if (deletedTowers.length > 0) {
      await axios.delete(endpoints.API_LOCATION_TOWERS_PATH(id), {
        data: {
          towers: deletedTowers,
        },
      });
    }

    if (newTowers.length > 0) {
      await axios.post(endpoints.API_LOCATION_TOWERS_PATH(id), {
        towers: newTowers,
      });
    }

    const { towers, boxes } = await axios.get(endpoints.API_LOCATION_TOWERS_PATH(id));

    return {
      boxes,
      towers,
    };
  },
});

const updateLockerCompanyLogic = createLogic({
  type: types.LOCKER_UPDATE_COMPANY_REQUEST,
  latest: true,
  processOptions: {
    failType: handleError(actions.lockerFailed),
    successType: types.LOCKER_UPDATE_COMPANY_REQUEST_SUCCEED,
  },
  async process({ axios, action }) {
    const {
      payload: { id, companyAssignment, currentCompany },
    } = action;

    const assignment = [];

    if (companyAssignment.network !== 'public') {
      assignment.push({
        company_id: companyAssignment.company,
        size: 's',
        wanted: +companyAssignment.small,
      });
      assignment.push({
        company_id: companyAssignment.company,
        size: 'm',
        wanted: +companyAssignment.medium,
      });
      assignment.push({
        company_id: companyAssignment.company,
        size: 'l',
        wanted: +companyAssignment.large,
      });
      assignment.push({
        company_id: companyAssignment.company,
        size: 'xl',
        wanted: +companyAssignment.extraLarge,
      });
    }

    if (currentCompany && companyAssignment.company !== currentCompany) {
      ['s', 'm', 'l', 'xl'].forEach((size) => assignment.push({
        size,
        company_id: currentCompany,
        wanted: 0,
      }));
    }

    const { location } = await axios.put(endpoints.API_LOCATION_COMPANY_PATH(id), {
      assignment,
    });

    return location;
  },
});

export default [
  getAllLockersLogic,
  getLockerLogic,
  postLockerLogic,
  updateLockerLogic,
  updateLockerNetworkLogic,
  updateLockerTowersLogic,
  updateLockerCompanyLogic,
];
