import React, { useCallback, useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import useAPI from '../../hooks/useAPI';
import * as endpoints from '../../constants/api';

export default function HardwareSelect(props) {
  const [versions, setVersions] = useState([]);
  const setResult = useCallback((res) => setVersions(res.hardware_versions), [setVersions]);
  const api = useAPI(endpoints.API_SETTINGS_PATH, 'get', setResult);

  return (
    <FormControl as="select" custom {...props}>
      <option disabled value="">Seleccionar</option>
      {
        api.isLoading && <option disabled>Cargando...</option>
      }
      {
        versions.map((entry) => (
          <option key={entry.name} value={entry.name}>{entry.display_name}</option>
        ))
      }
    </FormControl>
  );
}
