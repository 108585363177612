import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'rsuite';

export default function TimePicker(props) {
  const {
    value, onChange, onBlur, isInvalid, ...inputProps
  } = props;

  return (
    <DatePicker
      block
      placement="auto"
      format="HH:mm"
      locale={{
        hours: 'Horas',
        minutes: 'Minutos',
      }}
      ranges={[
        {
          label: '09:00',
          value: new Date(new Date().setHours(9, 0)),
        },
        {
          label: '18:00',
          value: new Date(new Date().setHours(18, 0)),
        },
        {
          label: '21:00',
          value: new Date(new Date().setHours(21, 0)),
        },
      ]}
      onSelect={onChange}
      onClean={() => onChange(null)}
      onExited={() => onBlur()}
      placeholder="HH:mm"
      value={value}
      {...inputProps}
      className={isInvalid ? 'invalid-picker' : ''}
    />
  );
}

TimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
  isInvalid: PropTypes.bool,
};
