import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from 'react-bootstrap/FormControl';
import useAPI from '../../hooks/useAPI';
import * as endpoints from '../../constants/api';

export default function ModelSelect(props) {
  const { readOnly, value } = props;
  const [models, setModels] = useState([]);
  const setResult = useCallback((res) => setModels(res.operating_models), [setModels]);
  const api = useAPI(endpoints.API_SETTINGS_PATH, 'get', setResult);

  if (readOnly) {
    return (
      <FormControl
        readOnly
        plaintext
        value={models.find((entry) => entry.name === value)?.display_name || ''}
      />
    );
  }

  return (
    <FormControl as="select" custom {...props}>
      <option disabled value="">Seleccionar</option>
      {
        api.isLoading && <option disabled>Cargando...</option>
      }
      {
        models.map((entry) => (
          <option key={entry.name} value={entry.name}>{entry.display_name}</option>
        ))
      }
    </FormControl>
  );
}

ModelSelect.propTypes = {
  readOnly: PropTypes.bool,
  value: PropTypes.string,
};
