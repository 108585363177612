import React from 'react';
import PropTypes from 'prop-types';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Row } from 'react-bootstrap';
import BoxDnD from './BoxDnD';

export function Tower(props) {
  const { id, boxes, onMove } = props;
  const findBox = (boxId) => {
    const box = boxes.filter((c) => c.id === boxId)[0];

    return {
      box,
      index: boxes.indexOf(box),
    };
  };
  const moveBox = (droppedId, atIndex) => {
    const target = findBox(droppedId);

    onMove(id, target, atIndex);
  };

  const type = `tower-dnd-${id}`;
  const [, drop] = useDrop({
    accept: type,
  });

  return (
    <Row ref={drop} xs={1} className="tower-drop-container" noGutters>
      {
        boxes.map((box) => (
          <BoxDnD
            key={box.id}
            id={box.id}
            acceptType={type}
            size={box.size}
            isMain={box.isMain}
            moveHandler={moveBox}
            findHandler={findBox}
          />
        ))
      }
    </Row>
  );
}

Tower.propTypes = {
  boxes: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.number.isRequired,
  onMove: PropTypes.func.isRequired,
};

export default function TowerDnD(props) {
  return (
    <DndProvider backend={HTML5Backend}>
      <Tower {...props} />
    </DndProvider>
  );
}
