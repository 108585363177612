import axios from 'axios';
import { sessionService } from 'redux-react-session';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

axiosInstance.interceptors.request.use(async (config) => {
  if (!config.url.includes('oauth')) {
    const token = await sessionService.loadSession();
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token.access_token}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (res) => res.data,
  async (err) => {
    if (err.response && err.response.status === 401) {
      await sessionService.deleteSession();
    }

    if (err.response) {
      return Promise.reject(err.response.data);
    }

    return Promise.reject(err);
  },
);

export default axiosInstance;
