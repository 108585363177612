import { createLogic } from 'redux-logic';
import * as types from '../constants/actions';
import * as endpoints from '../constants/api';
import * as actions from '../actions/firmware';
import handleError from './handleError';

const firmwareLogic = createLogic({
  type: types.FIRMWARE_REQUEST,
  latest: true,
  processOptions: {
    successType: actions.firmwareSucceed,
    failType: handleError(actions.firmwareFailed),
  },
  async process({ action, axios }) {
    const data = await axios.post(endpoints.API_FIRMWARE_PATH, action.payload);
    return {
      message: data.message,
      tokens: data.tokens,
    };
  },
});

const syncLockerLogic = createLogic({
  type: types.LOCKER_SYNC_REQUEST,
  latest: true,
  warnTimeout: 0,
  processOptions: {
    successType: types.LOCKER_SYNC_REQUEST_SUCCEEDED,
    failType: handleError(actions.firmwareFailed),
  },
  async process({ axios, action }) {
    const { locationId, version } = action.payload;
    const { message } = await axios.post(endpoints.API_LOCKER_SYNC_PATH(locationId), {
      version,
    });

    return { message };
  },
});

const syncLockerTowersLogic = createLogic({
  type: types.LOCKER_SYNC_TOWERS_REQUEST,
  latest: true,
  warnTimeout: 0,
  processOptions: {
    successType: types.LOCKER_SYNC_TOWERS_REQUEST_SUCCEEDED,
    failType: handleError(actions.firmwareFailed),
  },
  async process({ axios, action }) {
    const { locationId } = action.payload;

    await axios.post(endpoints.API_LOCKER_SYNC_TOWERS_PATH(locationId));

    return { message: 'Torres sincronizadas con exito' };
  },
});

export default [
  firmwareLogic,
  syncLockerLogic,
  syncLockerTowersLogic,
];
