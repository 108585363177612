import React from 'react';
import './Login.scss';
import { Row, Image } from 'react-bootstrap';
import Form from '../../components/Auth/Form';
import AuthNotification from '../../components/Auth/AuthNotification';
import BrandLogo from '../../assets/images/clicknbox.svg';
import * as routes from '../../constants/routes';

export function Login(props) {
  // eslint-disable-next-line react/prop-types
  const { location } = props;
  // eslint-disable-next-line react/prop-types
  const redirectTo = location.state?.from?.pathname || routes.HOME_PATH;

  return (
    <Row className="Login__container m-0">
      <AuthNotification />
      <Image src={BrandLogo} />
      <Form redirectTo={redirectTo} />
    </Row>
  );
}

export default Login;
