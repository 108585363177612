import React from 'react';
import './CajinLoader.scss';
import { Modal } from 'react-bootstrap';
import { ReactComponent as Cajin } from '../../assets/images/clicknbox_outer.svg';

export default function CajinLoader() {
  return (
    <Modal show size="sm" centered backdrop="static" id="cajin-loader">
      <Cajin />
    </Modal>
  );
}
