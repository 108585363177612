import React from 'react';
import PropTypes from 'prop-types';
import { Table, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LocationAdminDialog from './LocationAdminDialog';
import { BUILD_LOCKER_VIEW_PATH } from '../../constants/routes';

function versionBadgeVariantName(version) {
  switch (version) {
    case 'v1.6':
      return 'info';
    case 'v2.0':
      return 'warning';
    default:
      return 'dark';
  }
}

function modelBadgeVariantName(model) {
  switch (model) {
    case 'p2p':
      return 'attention-2';
    case 'cloud':
      return 'attention-1';
    default:
      return 'dark';
  }
}

export default function LocationsTable(props) {
  const { results } = props;

  const rows = results.map((result) => (
    <tr key={result.uuid} data-testid="row">
      <td>
        <Link to={BUILD_LOCKER_VIEW_PATH(result.id)}>{result.internal_name}</Link>
      </td>
      <td>
        {result.uuid}
        <Badge pill variant={versionBadgeVariantName(result.metadata.version)}>
          { result.metadata.version }
        </Badge>
        <Badge pill variant={modelBadgeVariantName(result.metadata.model)}>
          { result.metadata.model }
        </Badge>
      </td>
      <td>
        {result.address.state}
      </td>
      <td>
        {result.company}
      </td>
      <td className={result.connected ? 'text-success' : 'text-danger'}>
        {result.connected ? 'Online' : 'Offline'}
      </td>
      <td>
        <LocationAdminDialog
          locationId={result.id}
          name={result.internal_name}
          uuid={result.uuid}
          version={result.metadata.version}
          model={result.metadata.model}
        />
      </td>
    </tr>
  ));

  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>UUID</th>
          <th>Estado</th>
          <th>Compañía</th>
          <th>Estatus</th>
          <th>Administrar</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </Table>
  );
}

LocationsTable.propTypes = {
  results: PropTypes.instanceOf(Array),
};
