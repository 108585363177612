/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import * as PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Map from '../Map/Map';
import Towers from '../Towers/TowerSet';
import LocationSyncDialog from './LocationSyncDialog';
import LocationSyncTowersDialog from './LocationTowersSyncDialog';
import LocationAdminDialog from './LocationAdminDialog';
import * as util from '../../utils/lockerData';
import * as dates from '../../utils/dates';
import * as schedule from '../../utils/serviceDays';
import * as routes from '../../constants/routes';

export function Locker(props) {
  const { location } = props;

  return (
    <section>
      <h1>
        {location.internal_name} <small>{location.uuid}</small>
      </h1>
      <p className="text-muted">
        Creado el {dates.formatDate(location.created_at)}.
        <br />
        {location.internal_data?.rpi?.synced_at ? (
          `Última sincronización el ${dates.formatDate(location.internal_data.rpi.synced_at)}.`
        ) : (
          <span className="text-danger">Sin inicializar.</span>
        )}
      </p>

      <Row className="align-items-center mt-5 mb-5">
        <Col>
          <Towers towers={location.towers} boxes={location.boxes} />
        </Col>
        <Col xl={5} xs={12}>
          <div className="d-flex justify-content-end">
            <LocationSyncDialog
              locationId={location.id}
              isInstall={!location.internal_data?.rpi?.synced_at}
              uuid={location.uuid}
            />
            <LocationSyncTowersDialog locationId={location.id} />
            <Link to={routes.BUILD_LOCKER_EDIT_PATH(location.id)} className="btn btn-secondary ml-0 mr-2">
              Editar
            </Link>
            {location.internal_data?.rpi?.synced_at && (
              <LocationAdminDialog
                locationId={location.id}
                uuid={location.uuid}
                version={`v${location.internal_data?.rpi?.version}`}
                model={location.internal_data?.rpi?.operating_model?.name}
              />
            )}
          </div>
          <h6>RPi</h6>
          <p>
            <b className={location.node?.connected ? 'text-success' : 'text-danger'}>
              {location.node?.connected ? 'Online' : 'Offline'}
            </b>
            <br />
            <b>Versión: </b>
            {location.node?.metadata?.version || <span className="text-muted">Desconocida</span>}
            <br />
            <b>Servicios: </b>
            {location.node?.metadata?.services || <span className="text-muted">Desconocidos</span>}
          </p>

          <h6>Modelo de locker</h6>
          <p>
            <b>Versión de hardware: </b>
            {location.internal_data?.rpi?.version}
            <br />
            <b>Modelo operativo: </b>
            {location.internal_data?.rpi?.operating_model?.name?.toUpperCase() || (
              <span className="text-danger">No configurado</span>
            )}
          </p>

          <h6>Datos de red</h6>
          <p>
            <b>VPN IP: </b>
            {location.internal_data?.network?.vpn_ip || <span className="text-muted">No configurado</span>}
            <br />
            <b>SSID: </b>
            {location.internal_data?.network?.ssid || <span className="text-muted">No configurado</span>}
            <br />
            <b>Contraseña: </b>
            {location.internal_data?.network?.psw || <span className="text-muted">No configurado</span>}
          </p>

          <h6>Ubicación</h6>
          <p>
            <b>Host: </b>
            {location.company_host ? location.company_host.name : <span className="text-muted">No configurado</span>}
            <br />
            <b>Dirección: </b>
            {util.formatLockerLocation(location.address)}
            <br />
            <b>Referencias: </b>
            {util.formatLockerAddressReferences(location.address)}
            <br />
            <b>Estacionamiento: </b>
            {location.address?.parking ? 'Sí.' : 'No.'}
            <br />
            <b>Horario: </b>
            {schedule.formatServiceDays(location.service_days) || <span className="text-muted">No configurado</span>}
            <br />
            <b>Teléfono: </b>
            {location.phone || <span className="text-muted">No configurado</span>}
          </p>
          <Map lat={location.coords?.latitude} long={location.coords?.longitude} />
        </Col>
      </Row>
    </section>
  );
}

Locker.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.string,
    internal_name: PropTypes.string,
    created_at: PropTypes.string,
    uuid: PropTypes.string,
    towers: PropTypes.arrayOf(PropTypes.object),
    boxes: PropTypes.arrayOf(PropTypes.object),
    address: PropTypes.object,
    coords: PropTypes.object,
    phone: PropTypes.string,
    company_host: PropTypes.object,
    service_days: PropTypes.arrayOf(PropTypes.object),
    node: PropTypes.object,
    internal_data: PropTypes.object,
  }),
};

export default Locker;
