import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Col, Row, Spinner,
} from 'react-bootstrap';
import LogsTable from './LogTable';
import InputSearch from '../FormInputs/InputSearch';
import DateRangePicker from '../DatePickers/RangePicker';
import Pagination from '../Pagination/Pagination';
import useTable from '../../hooks/useTable';
import useAPI from '../../hooks/useAPI';
import * as endpoints from '../../constants/api';
import config from './config';
import { currentUNIX, endOfDayUNIX, isToday } from '../../utils/dates';

export default function LogsCard(props) {
  const { type } = props;
  const { title, subtitle } = config[type];
  const [url, setURL] = useState(`${endpoints.API_LOGS_PATH}?action=${type}`);
  const [result, setResult] = useState({ logs: [] });

  const api = useAPI(url, 'get', setResult);
  const helpers = useTable(result.logs, {
    pageSize: 10,
    searchFields: ['uuid', 'location'],
  });

  const renderBody = () => {
    if (api.isLoading) {
      return <Spinner animation="border" variant="secondary" className="spinner--full" />;
    }

    if (api.isError) {
      return <p className="empty-state">{ api.errorMessage }</p>;
    }

    if (helpers.isEmpty) {
      return <p className="empty-state">No hay coincidencias con tu búsqueda</p>;
    }

    return <LogsTable type={type} results={helpers.results} />;
  };

  const handleChange = (range) => {
    let newURL = `${endpoints.API_LOGS_PATH}?action=${type}`;

    if (range.length) {
      const [from, to] = range;
      const fromUNIX = Math.trunc(from.getTime() / 1000);
      const toUNIX = isToday(to)
        ? currentUNIX()
        : endOfDayUNIX(to);

      newURL += `&from=${fromUNIX}&to=${toUNIX}`;
    }

    setURL(newURL);
  };

  return (
    <Card>
      <Card.Header>
        <h2>{ title }</h2>
        <h4>{ subtitle }</h4>
        <Row className="align-items-center">
          <Col xs={12} sm={9} xl={6}>
            <InputSearch
              onSearch={helpers.handleSearch}
              placeholder="Buscar por UUID o locacion"
            />
          </Col>
          <Col sm={9} md={3} xl={{ span: 3, offset: 3 }}>
            <DateRangePicker minDaysAgo={21} handleChange={handleChange} />
          </Col>
        </Row>
      </Card.Header>
      {
        renderBody()
      }
      <Card.Footer>
        <Pagination
          current={helpers.pagination.current}
          total={helpers.pagination.total}
          handleChange={helpers.handlePage}
        />
      </Card.Footer>
    </Card>
  );
}

LogsCard.propTypes = {
  type: PropTypes.string,
};
