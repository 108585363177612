import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal,
} from 'react-bootstrap';

export default function NotificationModal(props) {
  const {
    variant, title, onHide, children, ...modalProps
  } = props;

  return (
    <Modal
      {...modalProps}
      show
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className={`text-${variant} text-center text-uppercase`}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {
          children
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          block
          variant="primary"
          className="text-uppercase"
          onClick={onHide}
        >
          finalizar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

NotificationModal.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
  onHide: PropTypes.func,
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};
