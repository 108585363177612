import React from 'react';
import { Container, Col } from 'react-bootstrap';
import Lockers from '../../components/Locations/Locations';

export default function DashboardPage() {
  return (
    <Container fluid>
      <Col>
        <Lockers />
      </Col>
    </Container>
  );
}
