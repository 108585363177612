import React from 'react';
import { Container, Col } from 'react-bootstrap';
import LogsCard from '../../components/MaintenanceLogs/LogsCard';
import config from '../../components/MaintenanceLogs/config';

export default function MaintenancePage() {
  return (
    <Container fluid>
      <Col>
        <h1>MANTENIMIENTOS</h1>
        {
          Object.keys(config).map((key) => <LogsCard key={key} type={key} />)
        }
      </Col>
    </Container>
  );
}
