import { combineReducers } from 'redux';
import { sessionReducer } from 'redux-react-session';
import lockersReducer from './lockers/reducer';
import firmwareReducer from './firmware/reducer';
import locationReducer from './location/reducer';
import authReducer from './auth/reducer';

const allReducers = combineReducers({
  session: sessionReducer,
  auth: authReducer,
  lockers: lockersReducer,
  firmware: firmwareReducer,
  location: locationReducer,
});

export default allReducers;
