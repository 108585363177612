export const API_TOKEN_PATH = '/oauth/authorize';
export const API_USER_PATH = '/oauth/user';
export const API_LOCKERS_PATH = '/admin/locations';
export const API_LOCKER_PATH = '/admin/locations/';
export const API_FIRMWARE_PATH = '/admin/command';
export const API_LOGS_PATH = '/admin/logs';
export const API_VALIDATE_ADDRESS = '/geocoding/address';
export const API_COMPANIES_PATH = '/companies';
export const API_SETTINGS_PATH = '/admin/locations/settings';
export const API_CREATE_LOCATION_PATH = '/locations';
export const API_UPDATE_LOCATION_PATH = (id) => `/locations/${id}`;
export const API_LOCATION_TOWERS_PATH = (id) => `/locations/${id}/towers`;
export const API_LOCATION_NETWORK_PATH = (id) => `/locations/${id}/internal/network`;
export const API_LOCATION_MODEL_PATH = (id) => `/locations/${id}/internal/operating_model`;
export const API_LOCATION_COMPANY_PATH = (id) => `/locations/${id}/companies`;
export const API_RPI_VERSIONS_PATH = '/admin/lockers/versions';
export const API_LOCKER_SYNC_PATH = (id) => `/admin/lockers/${id}/sync`;
export const API_LOCKER_SYNC_TOWERS_PATH = (id) => `/admin/lockers/${id}/towers/sync`;
export const API_GET_TOWERS = (id) => `/locations/${id}/towers`;
