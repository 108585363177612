/* eslint-disable max-len */
import { previewServiceDays } from './serviceDays';
import * as titles from '../constants/titles';

const NETWORKS = [
  {
    value: 'public',
    label: 'Red Pública',
    tooltip: titles.LOCKER_PUBLIC_NETWORK,
  },
  {
    value: 'private',
    label: 'Red Privada',
    tooltip: titles.LOCKER_PRIVATE_NETWORK,
  },
  {
    value: 'mixed',
    label: 'Red Mixta',
    tooltip: titles.LOCKER_MIXED_NETWORK,
  },
];

export const GENERAL_TAB = 'general';
export const TOWERS_TAB = 'towers';
export const COMPANY_TAB = 'company';
export const NETWORK_TAB = 'network';
export const PREVIEW_TAB = 'preview';
export const TABS = [
  {
    key: GENERAL_TAB,
    title: titles.LOCKERS_TAB_GENERAL,
  },
  {
    key: TOWERS_TAB,
    title: titles.LOCKERS_TAB_TOWERS,
  },
  {
    key: COMPANY_TAB,
    title: titles.LOCKERS_TAB_COMPANY,
  },
  {
    key: NETWORK_TAB,
    title: titles.LOCKERS_TAB_NETWORK,
  },
  {
    key: PREVIEW_TAB,
    title: 'Resumen',
  },
];

const SIZE_TO_KEY = {
  s: 'small',
  m: 'medium',
  l: 'large',
  xl: 'extra large',
};

function addressString(address) {
  if (!address) {
    return '';
  }
  return `${address.street} ${address.number}, ${address.town}, ${address.state} ${address.zip_code}`;
}

export function getCompanyNetworkSettings() {
  return NETWORKS;
}

export function getGeneralDataPreview(payload) {
  return `${payload.internal_name}. ${addressString(payload.address)}. ${previewServiceDays(payload)}`;
}

export function countBoxesOfSize(towers = [], size, company = '') {
  return towers.reduce(
    (cnt, tower) => cnt
      + tower.lockers.filter(
        (box) => box.size === size && !box.is_main && (company ? !box.company || box.company === company : true),
      ).length,
    0,
  );
}

export function getTowersPreview(towers) {
  if (towers.length < 1) {
    return 'Sin torres';
  }

  const details = [];

  details.push(`${towers.length} ${towers.length !== 1 ? 'torres' : 'torre'}.`);
  details.push(`${countBoxesOfSize(towers, 's')} casilleros chicos.`);
  details.push(`${countBoxesOfSize(towers, 'm')} casilleros medianos.`);
  details.push(`${countBoxesOfSize(towers, 'l')} casilleros grandes.`);
  details.push(`${countBoxesOfSize(towers, 'xl')} casilleros extra grandes.`);

  return details.join(' ');
}

export function getCompanyPreview(payload) {
  const { label } = NETWORKS.filter((v) => v.value === payload.network).pop();
  return `${payload.operating_model} - ${label}`;
}

export function getNetworkPreview(payload) {
  return `${payload.ssid}${payload.vpn_ip ? `. IP ${payload.vpn_ip}` : '.'}`;
}

export function formatLockerLocation(address) {
  if (!address) {
    return '';
  }

  const addressStr = [
    `${address.street} ${address.number}, ${address.town}, ${address.state}. C.P. ${address.zip_code}.`,
  ].join(' ');

  return addressStr;
}

export function formatLockerAddressReferences(address = {}) {
  const addressStr = [];

  if (address.cross_streets) {
    addressStr.push(`Entre calles ${address.cross_streets}.`);
  }

  if (address.references) {
    addressStr.push(address.references);
  }

  return addressStr.join(' ');
}

export function getCompanyAssignment(boxes = []) {
  const companies = boxes.reduce((arr, box) => {
    if (box.company) {
      return arr.concat(box.company.id);
    }

    return arr;
  }, []);

  if (companies.length < 1) {
    return {
      company: '',
      network: 'public',
    };
  }

  const isPrivate = boxes.every((box) => box.company !== null);
  const company = companies.pop();

  return boxes.reduce(
    (obj, box) => {
      if (box.company && box.company.id === company) {
        // eslint-disable-next-line no-param-reassign
        obj[SIZE_TO_KEY[box.size]] = box.total;
      }

      return obj;
    },
    {
      network: isPrivate ? 'private' : 'mixed',
      company,
    },
  );
}
