import * as titles from '../../constants/titles';

export default {
  [titles.CREATE_RESERVATION]: {
    headers: [],
    title: 'Reservaciones de prueba',
    subtitle: 'Buscar una reservación',
  },
  'open_(box|tower)': {
    headers: [
      {
        displayValue: 'Acción',
        key: 'action',
        value(entry) {
          if (entry.action === titles.OPEN_BOX) {
            return 'Apertura de puerta';
          }

          return 'Apertura de torre';
        },
      },
      {
        displayValue: 'Torre UUID',
        key: 'tower',
        value(entry) {
          return entry.uuid.substr(4, 3);
        },
      },
    ],
    title: 'Aperturas remotas',
    subtitle: 'Buscar apertura',
  },
  [titles.GET_IR]: {
    headers: [],
    title: 'Consultas de ocupación',
    subtitle: 'Buscar prueba',
  },
  [titles.GET_BOX]: {
    headers: [],
    title: 'Pruebas de cerradura',
    subtitle: 'Buscar prueba',
  },
  'reset_(tower|locker)': {
    headers: [
      {
        displayValue: 'Acción',
        key: 'action',
        value(entry) {
          if (entry.action === titles.RESET_TOWER) {
            return 'Prueba de Reseteo de módulo';
          }
          return 'Prueba de Reseteo de locker';
        },
      },
      {
        displayValue: 'Torre UUID',
        key: 'tower',
        value(entry) {
          return entry.uuid.substr(4, 3);
        },
      },
    ],
  },
  // [titles.RESET_TOWER]: {
  //   headers: [],
  //   title: 'Pruebas de cerradura',
  //   subtitle: 'Buscar prueba',
  // },
  // [titles.RESET_LOCKER]: {
  //   headers: [],
  //   title: 'Pruebas de cerradura',
  //   subtitle: 'Buscar prueba',
  // },
};
