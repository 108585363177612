import React from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer } from 'react-leaflet';
import Marker from './CustomMarker';
import './Map.scss';

export default function Map(props) {
  const { lat = 19.4779048, long = -99.1949062 } = props;

  return (
    <MapContainer center={[lat, long]} zoom={25} scrollWheelZoom={false} id="leaflet-map">
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker lat={lat} long={long} />
    </MapContainer>
  );
}

Map.propTypes = {
  lat: PropTypes.number,
  long: PropTypes.number,
};
