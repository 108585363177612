import { sessionService } from 'redux-react-session';
import axios from './api';
import * as endpoints from '../constants/api';

// eslint-disable-next-line import/prefer-default-export
export async function validateSession(credentials) {
  if (new Date(credentials.access_token_expires_at) < Date.now()) {
    return false;
  }

  const { user, scope } = await axios.get(endpoints.API_USER_PATH, {
    headers: {
      Authorization: `Bearer ${credentials.access_token}`,
    },
  });

  user.scope = scope;
  sessionService.saveUser(user);
  return true;
}
