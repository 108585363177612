import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';

import ContentModal from '../Modals/ContentModal';
import NotificationModal from '../Modals/NotificationModal';
import CajinLoader from '../Modals/CajinLoader';
import { ReactComponent as Error } from '../../assets/images/modal__error.svg';

import config from './factory';
import util from '../../utils/rpiCommands';
import * as titles from '../../constants/titles';
import { firmwareRequest } from '../../actions/firmware';

export default function ActionDialog(props) {
  const {
    onCancel, onComplete, locationId, action, subtitle,
  } = props;

  const dispatch = useDispatch();
  const isLoading = useSelector((store) => store.firmware.isLoading);
  const message = useSelector((store) => store.firmware.message);
  const isError = useSelector((store) => store.firmware.isError);

  const [showNotification, setShowNotification] = useState(false);
  const handleSubmit = (uuid) => {
    dispatch(firmwareRequest(uuid, action));
    setShowNotification(true);
  };

  const isDanger = isError || util[action].isDanger(message);
  const { title, Form } = config[action];

  if (showNotification && isLoading) {
    return <CajinLoader />;
  }

  const Status = isDanger ? Error : config[action][message];

  if (showNotification && Status) {
    return (
      <NotificationModal
        onHide={onComplete}
        title={isError ? message : titles.API_RESPONSES[action][message]}
        variant={isDanger ? 'danger' : 'success'}
      >
        <Status />
      </NotificationModal>
    );
  }

  return (
    <ContentModal animation={false} title={title} subtitle={subtitle} onHide={onCancel} exitTitle="regresar">
      <Form
        locationId={locationId}
        onSubmit={handleSubmit}
      />
    </ContentModal>
  );
}

ActionDialog.propTypes = {
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
  subtitle: PropTypes.string,
  locationId: PropTypes.string,
  action: PropTypes.string,
};
