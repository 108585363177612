import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import RangeInput from '../FormInputs/RangeInput';

export default function TowerConfiguration(props) {
  const {
    tower,
    setMain,
    onChange,
    canModify,
    isMainDisabled,
  } = props;
  const updateCount = (size) => (event) => {
    onChange(size, parseInt(event.target.value, 10));
  };

  return (
    <Form.Row>
      <Form.Check
        custom
        type="radio"
        label="Torre Maestra"
        name="isMain"
        value={tower.id}
        onChange={setMain}
        checked={tower.isMain}
        disabled={!canModify || isMainDisabled}
        id={`is-main-tower-${tower.id}`}
      />
      <Form.Group controlId={`tower-s-counts-${tower.id}`}>
        <Form.Label>Casilleros Chicos</Form.Label>
        {
          canModify && (
            <RangeInput
              max={14}
              onChange={updateCount('s')}
              value={tower.counts.s}
            />
          )
        }
        {!canModify && <p className="pl-2">{tower.counts.s}</p>}
      </Form.Group>

      <Form.Group controlId={`tower-m-counts-${tower.id}`}>
        <Form.Label>Casilleros Medianos</Form.Label>
        {
          canModify && (
            <RangeInput
              max={7}
              onChange={updateCount('m')}
              value={tower.counts.m}
            />
          )
        }
        {!canModify && <p className="pl-2">{tower.counts.m}</p>}
      </Form.Group>

      <Form.Group controlId={`tower-l-counts-${tower.id}`}>
        <Form.Label>Casilleros Grandes</Form.Label>
        {
          canModify && (
            <RangeInput
              max={tower.isMain ? 3 : 4}
              onChange={updateCount('l')}
              value={tower.counts.l}
            />
          )
        }
        {!canModify && <p className="pl-2">{tower.counts.l}</p>}
      </Form.Group>

      <Form.Group controlId={`tower-xl-counts-${tower.id}`}>
        <Form.Label size="sm">Casilleros Extra Grandes</Form.Label>
        {
          canModify && (
            <RangeInput
              max={tower.isMain ? 2 : 3}
              onChange={updateCount('xl')}
              value={tower.counts.xl}
            />
          )
        }
        {!canModify && <p className="pl-2">{tower.counts.xl}</p>}
      </Form.Group>
    </Form.Row>
  );
}

TowerConfiguration.propTypes = {
  tower: PropTypes.object,
  setMain: PropTypes.func,
  onChange: PropTypes.func,
  canModify: PropTypes.bool,
  isMainDisabled: PropTypes.bool,
};
