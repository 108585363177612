import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BUILD_LOCKER_VIEW_PATH } from '../../constants/routes';
import { formatDate } from '../../utils/dates';

export default function NewLocationsTable(props) {
  const { results } = props;

  const rows = results.map((result) => (
    <tr key={result.uuid} data-testid="row">
      <td>
        { formatDate(result.created_at, 'short') }
      </td>
      <td>
        { result.internal_name }
      </td>
      <td className={result.total_boxes < 1 ? 'text-info' : 'text-success'}>
        { result.total_boxes < 1 ? 'Incompleto' : 'Pendiente' }
      </td>
      <td>
        <Link to={BUILD_LOCKER_VIEW_PATH(result.id)} className="btn btn-secondary">
          &gt;&gt;
        </Link>
      </td>
    </tr>
  ));

  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th>Fecha</th>
          <th>Nombre</th>
          <th>Estatus</th>
          <th>Ver</th>
        </tr>
      </thead>
      <tbody>
        { rows }
      </tbody>
    </Table>
  );
}

NewLocationsTable.propTypes = {
  results: PropTypes.instanceOf(Array),
};
