import React, { useCallback, useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import useAPI from '../../hooks/useAPI';
import * as endpoints from '../../constants/api';

export default function CompanySelect(props) {
  const [companies, setCompanies] = useState([]);
  const setResult = useCallback((res) => setCompanies(res.companies), [setCompanies]);
  const api = useAPI(endpoints.API_COMPANIES_PATH, 'get', setResult);

  return (
    <FormControl as="select" custom {...props}>
      <option disabled value="">Seleccionar</option>
      {
        api.isLoading && <option disabled>Cargando...</option>
      }
      {
        companies.map((entry) => (
          <option key={entry.id} value={entry.id}>{entry.name}</option>
        ))
      }
    </FormControl>
  );
}
