import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';

import ContentModal from '../Modals/ContentModal';
import NotificationModal from '../Modals/NotificationModal';
import DoorForm from '../Forms/DoorForm';
import CajinLoader from '../Modals/CajinLoader';
import { ReactComponent as Error } from '../../assets/images/modal__error.svg';
import CodesList from '../Codes/CodesList';

import * as titles from '../../constants/titles';
import util from '../../utils/rpiCommands';
import { firmwareRequest } from '../../actions/firmware';

export default function ReservationDialog(props) {
  const {
    onCancel, onComplete, locationId, subtitle, action,
  } = props;

  const dispatch = useDispatch();
  const isLoading = useSelector((store) => store.firmware.isLoading);
  const message = useSelector((store) => store.firmware.message);
  const isError = useSelector((store) => store.firmware.isError);
  const tokens = useSelector((store) => store.firmware.tokens);

  const [showNotification, setShowNotification] = useState(false);
  const handleSubmit = (uuid) => {
    dispatch(firmwareRequest(uuid, action));
    setShowNotification(true);
  };

  if (showNotification && isLoading) {
    return <CajinLoader />;
  }

  const isDanger = isError || util[action].isDanger(message);

  if (showNotification && isDanger) {
    return (
      <NotificationModal onHide={onComplete} title={isError ? message : titles.RESERVATION_ERROR} variant="danger">
        <Error />
      </NotificationModal>
    );
  }

  if (showNotification && tokens) {
    return (
      <NotificationModal size="lg" onHide={onComplete} title={titles.RESERVATION_SUCCESS} variant="success">
        <CodesList tokens={tokens} />
      </NotificationModal>
    );
  }

  return (
    <ContentModal
      animation={false}
      title={titles.CREATE_RESERVATION_TITLE}
      subtitle={subtitle}
      onHide={onCancel}
      exitTitle="regresar"
    >
      <DoorForm locationId={locationId} onSubmit={handleSubmit} />
    </ContentModal>
  );
}

ReservationDialog.propTypes = {
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
  subtitle: PropTypes.string,
  locationId: PropTypes.string,
  action: PropTypes.string,
};
