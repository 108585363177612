import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  InputGroup, FormControl,
} from 'react-bootstrap';
import { ReactComponent as Search } from '../../assets/images/search_icon.svg';

export default function InputSearch(props) {
  const { onSearch, placeholder } = props;
  const [searchText, setSearchText] = useState('');
  const handleChange = (e) => {
    setSearchText(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <InputGroup size="lg">
      <InputGroup.Prepend>
        <InputGroup.Text><Search /></InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        placeholder={placeholder}
        aria-label={placeholder}
        value={searchText}
        onChange={handleChange}
      />
    </InputGroup>
  );
}

InputSearch.propTypes = {
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
};
