import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Tabs, Tab, Card, Button,
} from 'react-bootstrap';
import { Alert } from 'rsuite';
import PropTypes from 'prop-types';
import GeneralDataForm from '../Forms/GeneralDataForm';
import RegistryTowersForm from '../Forms/RegistryTowersForm';
import NetworkForm from '../Forms/NetworkForm';
import LockerCompanyForm from '../Forms/LockerCompanyForm';
import * as titles from '../../constants/titles';
import * as lockerUtils from '../../utils/lockerData';
import LockerPreview from './NewLockerPreview';
import './NewLockerTabs.scss';

export default function NewLockerTabs(props) {
  const { onSubmit } = props;
  const isLoading = useSelector((state) => state.location.isLoading);
  const isError = useSelector((state) => state.location.isError);
  const message = useSelector((state) => state.location.message);
  const [activeKey, setActiveKey] = useState(lockerUtils.GENERAL_TAB);
  const [generalData, setGeneralData] = useState({});
  const [towers, setTowers] = useState([]);
  const [companyData, setCompanyData] = useState({
    company: '',
    operating_model: '',
    network: 'private',
    small: 0,
    medium: 0,
    large: 0,
    extraLarge: 0,
  });
  const [networkData, setNetworkData] = useState({});
  // disable navigation by tabs
  const nullFn = () => {};
  const handleSubmit = () => {
    onSubmit(generalData, towers, companyData, networkData);
  };
  const next = (payload) => {
    const currentIndex = lockerUtils.TABS.findIndex((item) => item.key === activeKey);

    switch (activeKey) {
      case lockerUtils.GENERAL_TAB:
        setGeneralData(payload);
        break;
      case lockerUtils.TOWERS_TAB:
        setTowers(payload);
        break;
      case lockerUtils.COMPANY_TAB:
        setCompanyData(payload);
        break;
      case lockerUtils.NETWORK_TAB:
        setNetworkData(payload);
        break;
      default:
        break;
    }

    setActiveKey(lockerUtils.TABS[currentIndex + 1].key);
  };
  const prev = () => {
    const currentIndex = lockerUtils.TABS.findIndex((item) => item.key === activeKey);

    setActiveKey(lockerUtils.TABS[currentIndex - 1].key);
  };

  const smallCount = lockerUtils.countBoxesOfSize(towers, 's');
  const mediumCount = lockerUtils.countBoxesOfSize(towers, 'm');
  const largeCount = lockerUtils.countBoxesOfSize(towers, 'l');
  const extraLargeCount = lockerUtils.countBoxesOfSize(towers, 'xl');

  useEffect(() => {
    if (message && isError) {
      Alert.error(message, 3000);
    }

    if (message && !isError) {
      Alert.success(message, 3000);
    }
  }, [message, isError]);

  return (
    <Card>
      <h2>{titles.LOCKERS_TAB_TITLE}</h2>
      <Tabs activeKey={activeKey} onSelect={nullFn} className="tabs-no-navigation">
        <Tab eventKey={lockerUtils.GENERAL_TAB} title={titles.LOCKERS_TAB_GENERAL}>
          <GeneralDataForm onSubmit={next} initialValues={generalData} />
        </Tab>
        <Tab eventKey={lockerUtils.TOWERS_TAB} title={titles.LOCKERS_TAB_TOWERS}>
          <RegistryTowersForm onSubmit={next} onCancel={prev} towers={towers} />
        </Tab>
        <Tab eventKey={lockerUtils.COMPANY_TAB} title={titles.LOCKERS_TAB_COMPANY}>
          <LockerCompanyForm
            onSubmit={next}
            onCancel={prev}
            smallCount={smallCount}
            mediumCount={mediumCount}
            largeCount={largeCount}
            extraLargeCount={extraLargeCount}
            initialValues={companyData}
          />
        </Tab>
        <Tab eventKey={lockerUtils.NETWORK_TAB} title={titles.LOCKERS_TAB_NETWORK}>
          <NetworkForm onSubmit={next} onCancel={prev} initialValues={networkData} />
        </Tab>
        <Tab eventKey={lockerUtils.PREVIEW_TAB} title="Resumen">
          <LockerPreview
            generalPreview={lockerUtils.getGeneralDataPreview(generalData)}
            companyPreview={lockerUtils.getCompanyPreview(companyData)}
            networkPreview={lockerUtils.getNetworkPreview(networkData)}
            towersPreview={lockerUtils.getTowersPreview(towers)}
            onEdit={setActiveKey}
          />
          <Button block disabled={isLoading} onClick={handleSubmit} id="register-locker">
            {isLoading && <span className="spinner-border" role="status" aria-hidden="true" />}
            {!isLoading && 'Registrar locker'}
          </Button>
        </Tab>
      </Tabs>
    </Card>
  );
}

NewLockerTabs.propTypes = {
  onSubmit: PropTypes.func,
};
