import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as routes from '../constants/routes';

const PrivateRoute = ({ isAuthenticated, ...otherProps }) => {
  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: routes.LOGIN_PATH,
          state: { from: otherProps.location },
        }}
        push
      />
    );
  }

  return <Route {...otherProps} />;
};

const select = (state) => ({
  isAuthenticated: state.session.authenticated,
});

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
};

export default connect(select)(PrivateRoute);
