import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ContentModal from '../Modals/ContentModal';
import NotificationModal from '../Modals/NotificationModal';
import { ReactComponent as Error } from '../../assets/images/modal__error.svg';
import { ReactComponent as Success } from '../../assets/images/modal__success.svg';
import CajinLoader from '../Modals/CajinLoader';
import { syncTowers } from '../../actions/firmware';

const HIDDEN = 0;
const SHOW_COPY = 1;
const SHOW_OUTPUT = 2;

export default function LocationTowersSyncDialog(props) {
  const { locationId, uuid } = props;

  const dispatch = useDispatch();
  const isLoading = useSelector((store) => store.firmware.isLoading);
  const isError = useSelector((store) => store.firmware.isError);
  const output = useSelector((store) => store.firmware.message);

  const [step, setStep] = useState(HIDDEN);
  const handleClosed = (option) => {
    if (option === -1) {
      setStep(HIDDEN);
    } else {
      dispatch(syncTowers(locationId));
      setStep(SHOW_OUTPUT);
    }
  };

  if (isLoading) {
    return <CajinLoader />;
  }

  switch (step) {
    case SHOW_COPY:
      return (
        <ContentModal
          title="Sincronizar torres"
          onHide={handleClosed}
          buttons={[
            { key: 'confirm', variant: 'link-secondary', title: 'confirmar' },
          ]}
        >
          <p>
            <b>UUID: </b>
            {uuid}
          </p>
          <p className="text-muted">
            La RPi debe tener conexión a internet y tener al menos la versión
            <code> ^3.4.x. </code>
          </p>
        </ContentModal>
      );
    case SHOW_OUTPUT:
      return (
        <NotificationModal
          title={isError ? output : 'Torres sincronizadas'}
          variant={isError ? 'danger' : 'success'}
          onHide={() => handleClosed(-1)}
        >
          {
            isError ? <Error /> : <Success />
          }
        </NotificationModal>
      );
    default:
      return (
        <Button className="mr-3 ml-0" onClick={() => setStep(SHOW_COPY)}>
          Sincronizar torres
        </Button>
      );
  }
}

LocationTowersSyncDialog.propTypes = {
  locationId: PropTypes.string,
  uuid: PropTypes.string,
};
