import update from 'immutability-helper';

class Tower {
  constructor(id) {
    this.id = id;
    this.uuid = '';
    this.isMain = false;
    this.isDisabled = false;
    this.resetCounts();
  }

  /**
   * @private
   */
  resetCounts() {
    this.nextId = 1;
    this.counts = {
      s: 0,
      m: 0,
      l: 0,
      xl: 0,
    };
    this.boxes = [];
  }

  /**
   * @private
   */
  updateCounts() {
    this.boxes
      .filter((box) => !box.isMain)
      .forEach((box) => {
        this.counts[box.size]++;
      });
  }

  static from(obj) {
    const tower = new Tower(Number.parseInt(obj.uuid.substr(4), 10));

    tower.resetCounts();
    tower.uuid = obj.uuid;
    tower.isMain = obj.is_main;
    tower.isDisabled = true;
    tower.boxes = obj.lockers.map((box) => ({
      isMain: box.is_main,
      id: tower.getNextId(),
      size: box.size,
    }));
    tower.updateCounts();

    return tower;
  }

  clone() {
    const tower = new Tower(this.id);

    tower.uuid = this.uuid;
    tower.isMain = this.isMain;
    tower.nextId = this.nextId;
    tower.counts = { ...this.counts };
    tower.boxes = [...this.boxes];

    return tower;
  }

  toggleMain() {
    const tower = this.clone();

    tower.isMain = !this.isMain;

    if (tower.isMain) {
      tower.boxes = update(this.boxes, {
        $push: [
          {
            id: tower.getNextId(),
            isMain: true,
            size: 'l',
          },
        ],
      });
    } else {
      tower.boxes = [...this.boxes.filter((box) => !box.isMain)];
    }

    return tower;
  }

  /**
   * @private
   */
  getNextId() {
    const { nextId } = this;

    this.nextId++;
    return nextId;
  }

  /**
   * @private
   */
  reduceSizeCount(size, total) {
    const newBoxes = [...this.boxes];
    const { lastIndex } = newBoxes.reduce(
      (cnt, box, index) => {
        if (box.size !== size || box.isMain || cnt.boxesCount === total) {
          return cnt;
        }

        return { boxesCount: cnt.boxesCount + 1, lastIndex: index };
      },
      { boxesCount: 0, lastIndex: -1 },
    );

    this.boxes = newBoxes.filter((box, index) => {
      if (box.size !== size || box.isMain) {
        return true;
      }

      return index <= lastIndex;
    });
  }

  /**
   * @private
   */
  addBoxesOfSize(size, total) {
    const newBoxes = [...this.boxes];

    for (let i = 0; i < total; i++) {
      newBoxes.push({ isMain: false, size, id: this.getNextId() });
    }

    this.boxes = newBoxes;
  }

  setCount(size, count) {
    const currentCount = this.counts[size];
    const tower = this.clone();

    if (currentCount < count) {
      tower.addBoxesOfSize(size, count - currentCount);
    }

    if (currentCount > count) {
      tower.reduceSizeCount(size, count);
    }

    tower.counts[size] = count;
    return tower;
  }

  hasCapacityError() {
    const total = this.counts.s + this.counts.m * 2 + this.counts.l * 3 + (this.isMain ? 3 : 0);

    return total !== 14;
  }

  moveBox(index, dropIndex, box) {
    const tower = this.clone();

    tower.boxes = update(this.boxes, {
      $splice: [
        [index, 1],
        [dropIndex, 0, box],
      ],
    });

    return tower;
  }

  toJSON() {
    return {
      is_main: this.isMain,
      lockers: this.boxes.map((box) => ({
        size: box.size,
        is_main: box.isMain,
      })),
    };
  }
}

export default Tower;
