const itl = new Intl.DateTimeFormat('es-MX', {
  dateStyle: 'medium',
  timeStyle: 'short',
});
const itlShort = new Intl.DateTimeFormat('es-MX', {
  dateStyle: 'short',
});

export function subtractDays(days) {
  const date = new Date();

  date.setDate(new Date().getDate() - days);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
}

export function isToday(date) {
  const today = new Date();
  return date.getDate() === today.getDate()
    && date.getMonth() === today.getMonth()
    && date.getFullYear() === today.getFullYear();
}

export function currentUNIX() {
  const now = new Date();

  now.setSeconds(now.getSeconds() - 30);
  return Math.trunc(now.getTime() / 1000);
}

export function endOfDayUNIX(date) {
  const day = new Date(date);

  day.setHours(23, 59, 59);
  return Math.trunc(day.getTime() / 1000);
}

export function formatDate(date, format) {
  if (!date) {
    return '';
  }

  return format === 'short' ? itlShort.format(new Date(date)) : itl.format(new Date(date));
}
