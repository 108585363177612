import React, { useState, useCallback } from 'react';
import { useFormik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAPI from '../../hooks/useAPI';
import * as endpoints from '../../constants/api';

export default function DoorForm(props) {
  const {
    locationId,
    onSubmit,
  } = props;
  const [towers, setTowers] = useState([]);
  const setResult = useCallback((res) => setTowers(res.towers), [setTowers]);
  const api = useAPI(endpoints.API_GET_TOWERS(locationId), 'get', setResult);
  const formik = useFormik({
    initialValues: { uuid: '', index: '' },
    onSubmit(values, actions) {
      actions.setSubmitting(false);
      onSubmit(values.uuid);
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit} data-testid="door-form">
      <p>Ingresa la información de la puerta a utilizar</p>
      <Form.Group controlId="towerUuid" className="text-left">
        <Form.Label>No. torre</Form.Label>
        <Form.Control
          as="select"
          custom
          onChange={formik.handleChange}
          name="index"
          value={formik.values.index}
          data-testid="select-tower"
        >
          <option disabled value="">- Selecciona una torre -</option>
          {
            api.isLoading && <option disabled> Cargando... </option>
          }
          {
            !api.isLoading && towers.map((t, index) => (
              <option key={t.uuid} value={index} data-testid="tower-row">
                {t.uuid.substr(4)}
              </option>
            ))
          }
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="doorUuid" className="text-left">
        <Form.Label>No. puerta</Form.Label>
        <Form.Control
          as="select"
          custom
          onChange={formik.handleChange}
          name="uuid"
          value={formik.values.uuid}
          data-testid="select-door"
        >
          <option disabled value="">- Selecciona una puerta -</option>
          {
            api.isLoading && <option disabled> Cargando... </option>
          }
          {
            !api.isLoading && towers[formik.values.index]
              && towers[formik.values.index].lockers.map((box) => (
                <option key={box.uuid} value={box.uuid} data-testid="door-row">
                  {box.uuid.substr(7)}
                </option>
              ))
          }
        </Form.Control>
      </Form.Group>
      <Button
        block
        variant="primary"
        type="submit"
        disabled={formik.values.uuid === ''}
      >
        Continuar
      </Button>
    </Form>
  );
}

DoorForm.propTypes = {
  locationId: PropTypes.string,
  onSubmit: PropTypes.func,
};
