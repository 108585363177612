/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import util from '../../utils/rpiCommands';
import config from './config';

const date = new Intl.DateTimeFormat([]);
const time = new Intl.DateTimeFormat([], {
  timeStyle: 'short',
});

export default function LogsTable(props) {
  const { results, type } = props;
  const { headers } = config[type];

  const rows = results.map((result, index) => (
    <tr key={`${result.action}.${index}`} data-testid="row">
      <td>{result.location}</td>
      <td>{result.uuid}</td>
      {
        headers.map(({ key, value }) => (
          <td key={`${result.action}.${index}.${key}`}>{ value(result) }</td>
        ))
      }
      <td>{result.user}</td>
      <td>{date.format(new Date(result.time))}</td>
      <td>{time.format(new Date(result.time))}</td>
      <td className={`text-${util[result.action].textVariant(result.action_response)}`}>
        {util[result.action].toString(result.action_response)}
      </td>
    </tr>
  ));

  if (results.length < 1) {
    return <p className="empty-state">No se encontraron registros</p>;
  }

  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Box UUID</th>
          {
            headers.map(({ key, displayValue }) => (
              <th key={key}>{ displayValue }</th>
            ))
          }
          <th>Usuario</th>
          <th>Fecha</th>
          <th>Hora</th>
          <th>Respuesta</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </Table>
  );
}

LogsTable.propTypes = {
  type: PropTypes.string,
  results: PropTypes.instanceOf(Array),
};
