import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ContentModal from '../Modals/ContentModal';
import NotificationModal from '../Modals/NotificationModal';
import VersionSelect from '../FormInputs/VersionSelect';
import { ReactComponent as Error } from '../../assets/images/modal__error.svg';
import CajinLoader from '../Modals/CajinLoader';
import { syncLocker } from '../../actions/firmware';

const HIDDEN = 0;
const SELECT_VERSION = 1;
const SHOW_COPY = 2;
const SHOW_OUTPUT = 3;

export default function LocationSyncDialog(props) {
  const { isInstall, locationId, uuid } = props;

  const dispatch = useDispatch();
  const isLoading = useSelector((store) => store.firmware.isLoading);
  const isError = useSelector((store) => store.firmware.isError);
  const output = useSelector((store) => store.firmware.message);

  const [step, setStep] = useState(HIDDEN);
  const [selectedVersion, setSelected] = useState('');
  const handleChange = (e) => setSelected(e.target.value);
  const handleClosed = (option) => {
    if (option === -1) {
      setStep(HIDDEN);
      setSelected('');
    } else if (step === SELECT_VERSION) {
      setStep(SHOW_COPY);
    } else {
      dispatch(syncLocker(locationId, selectedVersion));
      setStep(SHOW_OUTPUT);
    }
  };

  if (isLoading) {
    return <CajinLoader />;
  }

  switch (step) {
    case SELECT_VERSION:
      return (
        <ContentModal
          title="Sincronizar RPi"
          onHide={handleClosed}
          buttons={[
            {
              key: 'continue',
              variant: 'link-secondary',
              title: 'Continuar',
              disabled: selectedVersion === '',
            },
          ]}
        >
          <p>
            Selecciona la versión a instalar.
          </p>
          <VersionSelect value={selectedVersion} onChange={handleChange} />
        </ContentModal>
      );
    case SHOW_COPY:
      return (
        <ContentModal
          title="Sincronizar RPi"
          onHide={handleClosed}
          buttons={[
            { key: 'confirm', variant: 'link-secondary', title: 'confirmar' },
          ]}
        >
          <p>
            <b>UUID: </b>
            {uuid}
          </p>
          <p>
            <b>Versión a instalar: </b>
            { selectedVersion }
          </p>
          <p className="text-muted">
            La RPi debe tener conexión a internet y tener instalado
            <code> rpi-setup-tool. </code>
          </p>
        </ContentModal>
      );
    case SHOW_OUTPUT:
      return (
        <NotificationModal
          title={isError ? output : 'Sincronización exitosa'}
          variant={isError ? 'danger' : 'success'}
          onHide={() => handleClosed(-1)}
        >
          {
            !isError && (
              <p className="text-muted">
                Se inició la ejecución del playbook, en caso de
                éxito los cambios se verán reflejados en unos minutos.
              </p>
            )
          }
          {
            isError && <Error />
          }
        </NotificationModal>
      );
    default:
      return (
        <Button className="mr-3 ml-0" onClick={() => setStep(SELECT_VERSION)}>
          { isInstall ? 'Configurar RPi' : 'Actualizar versión'}
        </Button>
      );
  }
}

LocationSyncDialog.propTypes = {
  locationId: PropTypes.string,
  uuid: PropTypes.string,
  isInstall: PropTypes.bool,
};
