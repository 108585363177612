/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Provider } from 'react-redux';
import { sessionService } from 'redux-react-session';
import { setLocale } from 'yup';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './configureStore';
import { validateSession } from './utils/auth';
import * as routes from './constants/routes';
import formErrors from './constants/yup';

console.log(`Running version: ${process.env.REACT_APP_VERSION}`);
console.log(`Using API: ${process.env.REACT_APP_API_URL}`);
console.log(`Environment: ${process.env.NODE_ENV}`);

sessionService.initSessionService(store, {
  redirectPath: routes.LOGIN_PATH,
  driver: 'COOKIES',
  validateSession,
});

setLocale(formErrors);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
