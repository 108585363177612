import initialState from './initialState';
import * as types from '../../constants/actions';
import * as titles from '../../constants/titles';
import * as errors from '../../constants/errors';

const lockersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOCKER_CREATE_REQUEST:
    case types.LOCKER_REQUEST:
      return {
        ...initialState,
        isLoading: true,
      };
    case types.LOCKER_UPDATE_COMPANY_REQUEST:
    case types.LOCKER_UPDATE_TOWERS_REQUEST:
    case types.LOCKER_UPDATE_NETWORK_REQUEST:
    case types.LOCKER_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.LOCKER_UPDATE_REQUEST_SUCCEED:
      return {
        message: titles.LOCKER_SUCCESS_MESSAGE,
        isError: false,
        isLoading: false,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case types.LOCKER_UPDATE_NETWORK_REQUEST_SUCCEED:
      return {
        message: titles.LOCKER_SUCCESS_MESSAGE,
        isError: false,
        isLoading: false,
        data: {
          ...state.data,
          internal_data: {
            ...state.data.internal_data,
            network: action.payload,
          },
        },
      };
    case types.LOCKER_UPDATE_TOWERS_REQUEST_SUCCEED:
      return {
        message: titles.LOCKER_SUCCESS_MESSAGE,
        isError: false,
        isLoading: false,
        data: {
          ...state.data,
          towers: action.payload.towers,
          boxes: action.payload.boxes,
        },
      };
    case types.LOCKER_UPDATE_COMPANY_REQUEST_SUCCEED:
      return {
        isError: false,
        isLoading: false,
        message: titles.LOCKER_SUCCESS_MESSAGE,
        data: action.payload,
      };
    case types.LOCKER_REQUEST_SUCCEED:
      return {
        isError: false,
        isLoading: false,
        message: state.message,
        data: action.payload,
      };
    case types.LOCKER_REQUEST_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        message: errors[action.payload.type] || errors.DEFAULT_ERROR,
      };
    case types.INVALID_AUTHENTICATION:
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
      };
    case types.LOCKER_CREATE_REQUEST_SUCCEED:
      return {
        ...state,
        message: titles.LOCKER_CREATE_REQUEST_SUCCEED,
      };
    default:
      return {
        ...state,
      };
  }
};

export default lockersReducer;
