export const keys = [
  'delivery_token',
  'reopen_1_token',
  'reopen_2_token',
  'pickup_token',
  'cancel_token',
];

export const labels = {
  delivery_token: 'Código de entrega',
  reopen_1_token: 'Código de primera edición',
  reopen_2_token: 'Código de segunda edición',
  pickup_token: 'Código de recolección',
  cancel_token: 'Código de cancelación',
};
