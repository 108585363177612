import * as actions from '../constants/actions';

export function firmwareRequest(uuid, action) {
  return {
    type: actions.FIRMWARE_REQUEST,
    payload: {
      uuid,
      action,
    },
  };
}

export function firmwareSucceed({ message, tokens }) {
  return {
    type: actions.FIRMWARE_REQUEST_SUCCEED,
    payload: {
      message,
      tokens,
    },
  };
}

export function firmwareFailed(err) {
  return {
    type: actions.FIRMWARE_REQUEST_FAILED,
    payload: {
      message: err.message,
      type: err.type,
      data: err.data,
    },
  };
}

export function syncLocker(locationId, version) {
  return {
    type: actions.LOCKER_SYNC_REQUEST,
    payload: {
      locationId,
      version,
    },
  };
}

export function syncTowers(locationId) {
  return {
    type: actions.LOCKER_SYNC_TOWERS_REQUEST,
    payload: {
      locationId,
    },
  };
}
