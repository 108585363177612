import * as actions from '../actions/auth';

export default function handleError(defaultAction) {
  return (err) => {
    if (err.status_code && err.status_code === 401) {
      return actions.unauthenticated(err);
    }

    return defaultAction(err);
  };
}
