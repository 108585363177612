import { createLogic } from 'redux-logic';
import { sessionService } from 'redux-react-session';
import * as types from '../constants/actions';
import * as endpoints from '../constants/api';
import * as actions from '../actions/auth';

const loginLogic = createLogic({
  type: types.LOGIN_REQUEST,
  latest: true,
  processOptions: {
    successType: actions.loginSucceed,
    failType: actions.loginFailed,
  },
  async process({ action, axios }) {
    const { email, password } = action.payload;
    const params = new URLSearchParams();

    params.append('grant_type', 'password');
    params.append('username', email);
    params.append('password', password);
    params.append('client_id', process.env.REACT_APP_CLIENT_ID);

    const { user, scope, ...session } = await axios.post(endpoints.API_TOKEN_PATH, params);

    user.scope = scope;
    sessionService.saveSession(session);
    sessionService.saveUser(user);
  },
});

export default [
  loginLogic,
];
