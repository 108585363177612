import React, { useEffect } from 'react';
import { sessionService } from 'redux-react-session';
import { Redirect } from 'react-router-dom';
import * as routes from '../../constants/routes';

export default function LogoutHandler() {
  useEffect(() => {
    sessionService.deleteSession();
  }, []);

  return <Redirect to={routes.LOGIN_PATH} />;
}
