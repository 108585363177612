import React from 'react';
import { Container, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import NewLockerTabs from '../../components/Locations/NewLockerTabs';
import * as locker from '../../actions/lockers';

export function NewLockerPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = (...payload) => dispatch(locker.createLocker(history, ...payload));

  return (
    <Container fluid>
      <Col>
        <h1>LOCKERS</h1>
        <NewLockerTabs onSubmit={onSubmit} />
      </Col>
    </Container>
  );
}

export default NewLockerPage;
