import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import './Box.scss';

export default function BoxDnD(props) {
  const {
    size, id, isMain, moveHandler, acceptType, findHandler,
  } = props;
  const { index } = findHandler(id);

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: acceptType,
      id,
      size,
      originalIndex: index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();

      // dropped in invalid tower/outside
      if (!didDrop) {
        moveHandler(item.id, item.originalIndex);
      }
    },
  });
  const [{ isOver }, drop] = useDrop({
    accept: acceptType,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    canDrop: () => false,
    hover(draggedItem, monitor) {
      if (draggedItem.id === id) {
        return;
      }

      const clientOffset = monitor.getDifferenceFromInitialOffset();
      const { index: draggedIndex } = findHandler(draggedItem.id);
      const direction = index > draggedIndex ? 1 : -1;

      // comes from above = 1
      // comes from below = -1
      if (direction * clientOffset.y >= direction * 47) {
        moveHandler(draggedItem.id, index);
      }
    },
  });

  const classNames = `tower-box box-${size} ${isOver ? 'drag-over' : ''} ${isDragging ? 'dragging' : ''} ${isMain ? 'is-main' : ''}`;

  return (
    <Col ref={(node) => drag(drop(node))} className={classNames} data-testid="tower-box">
      { isMain ? 'pantalla' : index + 1 }
    </Col>
  );
}

BoxDnD.propTypes = {
  id: PropTypes.number.isRequired,
  acceptType: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  isMain: PropTypes.bool,
  moveHandler: PropTypes.func.isRequired,
  findHandler: PropTypes.func.isRequired,
};
