import React, { useState, useCallback } from 'react';
import { useFormik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAPI from '../../hooks/useAPI';
import * as endpoints from '../../constants/api';

export default function TowerForm(props) {
  const { locationId, onSubmit } = props;
  const [towers, setTowers] = useState([]);
  const setResult = useCallback((res) => setTowers(res.towers), [setTowers]);
  const formik = useFormik({
    initialValues: { uuid: '' },
    onSubmit(values, actions) {
      actions.setSubmitting(false);
      onSubmit(values.uuid);
    },
  });
  const api = useAPI(endpoints.API_GET_TOWERS(locationId), 'get', setResult);

  return (
    <Form onSubmit={formik.handleSubmit} data-testid="tower-form">
      <p>Ingresa la información de la torre a modificar</p>
      <Form.Group controlId="towerUuid" className="text-left">
        <Form.Label>No. torre</Form.Label>
        <Form.Control
          as="select"
          custom
          onChange={formik.handleChange}
          name="uuid"
          value={formik.values.uuid}
          data-testid="select-tower"
        >
          <option disabled value="">
            - Selecciona una torre -
          </option>
          {api.isLoading && <option disabled> Cargando... </option>}
          {!api.isLoading
            && towers.map((t) => (
              <option key={t.uuid} value={t.lockers[t.lockers.length - 1].uuid} data-testid="tower-row">
                {t.uuid.substr(4)}
              </option>
            ))}
        </Form.Control>
      </Form.Group>
      <Button block variant="primary" type="submit" disabled={formik.values.uuid === ''}>
        Continuar
      </Button>
    </Form>
  );
}

TowerForm.propTypes = {
  locationId: PropTypes.string,
  onSubmit: PropTypes.func,
};
