import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export default function SelectBoxes(props) {
  const { id, max, ...inputProps } = props;

  return (
    <Form.Control
      as="select"
      custom
      {...inputProps}
    >
      {
        Array(max + 1).fill(1).map((_, index) => (
          <option value={index} key={`${id}-${index}`}>
            { index }
          </option>
        ))
      }
    </Form.Control>
  );
}

SelectBoxes.propTypes = {
  id: PropTypes.string,
  max: PropTypes.number,
};
