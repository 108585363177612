import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import LocationsTable from './LocationsTable';
import InputSearch from '../FormInputs/InputSearch';
import Pagination from '../Pagination/Pagination';
import { ReactComponent as Refresh } from '../../assets/images/refresh.svg';
import { lockersRequest } from '../../actions/lockers';
import useTable from '../../hooks/useTable';

export function Locations(props) {
  const {
    results, isLoading, onRefresh,
  } = props;
  const helpers = useTable(results, {
    pageSize: 10,
    searchFields: ['uuid', 'internal_name', 'company', 'address.state'],
  });
  const refreshSearch = () => onRefresh();

  return (
    <Card>
      <Card.Header>
        <h4 className="mt-0">Buscar un locker</h4>
        <Row className="align-items-center">
          <Col xs={12} sm={9} xl={6}>
            <InputSearch
              onSearch={helpers.handleSearch}
              placeholder="Buscar por UUID, nombre o compañía"
            />
          </Col>
          <Col>
            <Button
              onClick={refreshSearch}
              disabled={isLoading}
              variant="outline-secondary"
              className="float-right"
              size="lg"
            >
              {
              isLoading
                ? <span className="spinner-border spinner-border" role="status" aria-hidden="true" />
                : <Refresh className="svg-secondary" />
              }
            </Button>
          </Col>
        </Row>
      </Card.Header>
      {
        helpers.isEmpty && !isLoading
          ? <p className="empty-state">No hay coincidencias con tu búsqueda</p>
          : <LocationsTable results={helpers.results} />
      }
      <Card.Footer>
        <Pagination
          current={helpers.pagination.current}
          total={helpers.pagination.total}
          handleChange={helpers.handlePage}
        />
        <span className="pagination-text">
          { helpers.pagination.text }
        </span>
      </Card.Footer>
    </Card>
  );
}

Locations.propTypes = {
  onRefresh: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  results: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default function ConnectedLocations() {
  const dispatch = useDispatch();
  const list = useSelector((store) => store.lockers.list);
  const isLoading = useSelector((store) => store.lockers.isLoading);
  const onRefresh = useCallback(() => {
    dispatch(lockersRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(lockersRequest());
  }, [dispatch]);

  return (
    <Locations
      isLoading={isLoading}
      results={list.filter((loc) => loc.internal_data?.rpi?.synced_at)}
      onRefresh={onRefresh}
    />
  );
}
