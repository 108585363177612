import { useState } from 'react';
import Fuse from 'fuse.js';

export default function useTable(results, opts) {
  const [currentPage, setPage] = useState(1);
  const [searchText, setCurrentSearch] = useState('');
  const fuse = new Fuse(results, {
    includeMatches: true,
    minMatchCharLength: 2,
    threshold: 0.3,
    distance: 20,
    shouldSort: true,
    keys: opts.searchFields,
  });

  const filteredResults = searchText.length >= 2
    ? fuse.search(searchText).map((row) => row.item)
    : results;
  const totalPages = Math.ceil(filteredResults.length / opts.pageSize);
  const startPos = (currentPage - 1) * opts.pageSize;
  const currentPageResults = filteredResults.slice(startPos, startPos + opts.pageSize);

  return {
    results: currentPageResults,
    isEmpty: filteredResults.length === 0 && searchText.length >= 1,
    pagination: {
      current: currentPage,
      total: totalPages,
      text: `${startPos + 1} - ${startPos + currentPageResults.length} de ${filteredResults.length} `,
    },
    handleSearch(value) {
      setPage(1);
      setCurrentSearch(value);
    },
    handlePage: setPage,
  };
}
