/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CompanySelect from '../FormInputs/CompanySelect';
import ModelSelect from '../FormInputs/ModelSelect';
import RangeInput from '../FormInputs/RangeInput';
import * as titles from '../../constants/titles';
import * as lockerUtils from '../../utils/lockerData';
import './LockerCompanyForm.scss';

export default function LockerCompanyForm(props) {
  const {
    onSubmit, onCancel, smallCount, mediumCount, largeCount, extraLargeCount, initialValues, isEdit,
  } = props;
  const formik = useFormik({
    initialValues: {
      company: '',
      small: 0,
      medium: 0,
      large: 0,
      extraLarge: 0,
      operating_model: '',
      ...initialValues,
    },
    initialErrors: {
      privateBoxes: '',
    },
    onSubmit(values, actions) {
      actions.setSubmitting(false);
      const finalValues = {
        operating_model: values.operating_model,
        network: values.network,
        company: values.network === 'public' ? '' : values.company,
        small: values.network === 'private' ? smallCount : values.small,
        medium: values.network === 'private' ? mediumCount : values.medium,
        large: values.network === 'private' ? largeCount : values.large,
        extraLarge: values.network === 'private' ? extraLargeCount : values.extraLarge,
      };

      onSubmit(finalValues);
      actions.resetForm({ values: finalValues });
    },
    validationSchema: Yup.object({
      operating_model: Yup.string().required(),
      network: Yup.string()
        .required()
        .when('operating_model', {
          is: 'cnc',
          then: Yup.string().oneOf(['mixed', 'private', 'public']),
          otherwise: Yup.string().oneOf(['private'], titles.LOCKER_MODEL_NETWORK_COMPATIBILITY),
        }),
      company: Yup.string().when('network', {
        is: 'public',
        otherwise: Yup.string().required(),
      }),
      small: Yup.number().max(smallCount, titles.LOCKER_OVER_CAPACITY_ASSIGNED),
      medium: Yup.number().max(mediumCount, titles.LOCKER_OVER_CAPACITY_ASSIGNED),
      large: Yup.number().max(largeCount, titles.LOCKER_OVER_CAPACITY_ASSIGNED),
      extraLarge: Yup.number().max(extraLargeCount, titles.LOCKER_OVER_CAPACITY_ASSIGNED),
    }),
    validate(values) {
      const total = +values.small + +values.medium + +values.large + +values.extraLarge;
      const errors = {};

      if (values.network === 'mixed' && total === 0) {
        errors.privateBoxes = titles.LOCKER_COMPANY_BOXES_REQUIRED;
      }

      return errors;
    },
  });

  return (
    <fieldset>
      <Form.Row>
        <Form.Group controlId="operatingModel" as={Col} xs={12} md={4}>
          <Form.Label>Modelo operativo</Form.Label>
          <ModelSelect
            name="operating_model"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.operating_model}
            readOnly={isEdit}
            isInvalid={formik.errors.operating_model && formik.touched.operating_model}
          />
          <Form.Control.Feedback type="invalid">{formik.errors.operating_model}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="network" as={Col} xs={12} md={8}>
          <Form.Label>Tipo de Locker</Form.Label>
          <Form.Row>
            {lockerUtils.getCompanyNetworkSettings().map((item) => (
              <Form.Check
                type="radio"
                value={item.value}
                label={item.label}
                name="network"
                checked={formik.values.network === item.value}
                onChange={formik.handleChange}
                bsPrefix="pill-control"
                inline
                id={`${item.value}-network`}
                key={`${item.value}-network`}
                title={item.tooltip}
              />
            ))}
          </Form.Row>
          <Form.Control.Feedback type="invalid" className={formik.touched.network ? 'd-block' : ''}>
            {formik.errors.network}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group controlId="company" as={Col} md={6} xs={12}>
          <Form.Label>Compañía</Form.Label>
          <CompanySelect
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.company}
            isInvalid={formik.errors.company && formik.touched.company}
            disabled={formik.values.network === 'public'}
          />
          <Form.Control.Feedback type="invalid">{formik.errors.company}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        {[
          { label: 'Casilleros Chicos', name: 'small', max: smallCount },
          { label: 'Casilleros Medianos', name: 'medium', max: mediumCount },
          { label: 'Casilleros Grandes', name: 'large', max: largeCount },
          { label: 'Casilleros Extra Grandes', name: 'extraLarge', max: extraLargeCount },
        ].map((conf) => (
          <Form.Group controlId={`company-${conf.name}-boxes`} as={Col} md={4} xs={12} key={conf.name}>
            <Form.Label>{conf.label}</Form.Label>
            <RangeInput
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={conf.name}
              max={conf.max}
              value={formik.values[conf.name]}
              isInvalid={formik.touched[conf.name] && formik.errors[conf.name]}
              disabled={formik.values.network !== 'mixed' || conf.max === 0}
            />
            <Form.Control.Feedback type="invalid">{formik.errors[conf.name]}</Form.Control.Feedback>
          </Form.Group>
        ))}
        <Col>
          <Form.Text className="text-danger" style={{ marginTop: '-10px' }}>
            {(formik.touched.small || formik.touched.medium || formik.touched.large || formik.touched.extraLarge)
              && formik.errors.privateBoxes}
          </Form.Text>
        </Col>
      </Form.Row>
      <Form.Row>
        {!isEdit && (
          <Col className="text-right">
            <Button variant="light" size="lg" onClick={onCancel}>
              Regresar
            </Button>
          </Col>
        )}
        <Col className={isEdit ? 'text-right' : 'text-left'}>
          <Button variant="primary" size="lg" onClick={formik.submitForm} disabled={!formik.dirty}>
            {isEdit ? 'Guardar' : 'Siguiente'}
          </Button>
        </Col>
      </Form.Row>
    </fieldset>
  );
}

LockerCompanyForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  smallCount: PropTypes.number,
  mediumCount: PropTypes.number,
  largeCount: PropTypes.number,
  extraLargeCount: PropTypes.number,
  initialValues: PropTypes.shape({
    company: PropTypes.string,
    network: PropTypes.string,
    small: PropTypes.number,
    medium: PropTypes.number,
    large: PropTypes.number,
    extraLarge: PropTypes.number,
  }),
  isEdit: PropTypes.bool,
};
