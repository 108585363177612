/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react';
import {
  Container, Col,
} from 'react-bootstrap';
import { Placeholder } from 'rsuite';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import LockerView from '../../components/Locations/LocationView';
import * as actions from '../../actions/lockers';

export function Locker() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useSelector((store) => store.location.data);
  const isLoading = useSelector((store) => store.location.isLoading);
  const isError = useSelector((store) => store.location.isError);
  const message = useSelector((store) => store.location.message);

  useEffect(() => {
    dispatch(actions.lockerRequest(id, true));
  }, [id, dispatch]);

  return (
    <Container fluid>
      <Col>
        {
          isLoading && (
          <section className="mt-5">
            <Placeholder.Paragraph active />
            <Placeholder.Graph active height={670} />
          </section>
          )
        }
        {
          !isLoading && <LockerView location={location} message={message} isError={isError} />
        }
      </Col>
    </Container>
  );
}

export default Locker;
