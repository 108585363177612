const FULLTIME = '00:00';
const STR_WEEKDAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
const STR_WEEKENDS = ['saturday', 'sunday'];
const WEEKDAYS = {
  monday: 'Lu',
  tuesday: 'Ma',
  wednesday: 'Mie',
  thursday: 'Jue',
  friday: 'Vie',
  saturday: 'Sab',
  sunday: 'Dom',
};

// hh:mm -> date
function parseTime(time) {
  const date = new Date();

  date.setHours(time.substr(0, 2));
  date.setMinutes(time.substr(3, 2));
  return date;
}

// date -> hh:mm
function getTime(date) {
  if (!date) {
    return '';
  }

  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
}

// build API service_days array
export function getServiceDays(values) {
  const getDay = (day, fulltime, opening, closing) => ({
    weekday: day,
    opening_time: fulltime
      ? FULLTIME
      : getTime(opening),
    closing_time: fulltime
      ? FULLTIME
      : getTime(closing),
  });
  const weekdays = Array(5)
    .fill({})
    .map((_, i) => getDay(
      i,
      values.fulltime_weekdays,
      values.opening_weekdays,
      values.closing_weekdays,
    ));
  const weekends = values.opening_weekends instanceof Date || values.fulltime_weekends
    ? [
      getDay(
        5,
        values.fulltime_weekends,
        values.opening_weekends,
        values.closing_weekends,
      ),
      getDay(
        6,
        values.fulltime_weekends,
        values.opening_weekends,
        values.closing_weekends,
      ),
    ] : [];

  return weekdays.concat(weekends);
}

// parses { opening_time, closing_time }
function parseDay(schedule) {
  if (!schedule) {
    return {
      opening: undefined,
      closing: undefined,
      fulltime: false,
    };
  }

  return {
    opening: parseTime(schedule.opening_time),
    closing: parseTime(schedule.closing_time),
    fulltime: schedule.opening_time === FULLTIME
      && schedule.closing_time === FULLTIME,
  };
}

// build Location opening/closing object
export function parseServiceDays(serviceDays = []) {
  const weekdays = serviceDays.filter((day) => STR_WEEKDAYS.includes(day.string_weekday));
  const parsedWeekdays = parseDay(weekdays.pop());
  const weekends = serviceDays.filter((day) => STR_WEEKENDS.includes(day.string_weekday));
  const parsedWeekends = parseDay(weekends.pop());

  return {
    opening_weekdays: parsedWeekdays.opening,
    closing_weekdays: parsedWeekdays.closing,
    fulltime_weekdays: parsedWeekdays.fulltime,
    opening_weekends: parsedWeekends.opening,
    closing_weekends: parsedWeekends.closing,
    fulltime_weekends: parsedWeekends.fulltime,
  };
}

function previewServiceDay(prefix, isFulltime, opening, closing) {
  if (isFulltime) {
    return `${prefix} 24hrs.`;
  }

  return `${prefix} - ${getTime(opening)} a ${getTime(closing)} hrs.`;
}

function getWeekDay(payload) {
  return previewServiceDay('Lu a Vie', payload.fulltime_weekdays, payload.opening_weekdays, payload.closing_weekdays);
}

function getWeekEnds(payload) {
  return previewServiceDay('Sab a Dom', payload.fulltime_weekends, payload.opening_weekends, payload.closing_weekends);
}

// Human readable service days format
export function formatServiceDays(days = []) {
  if (days.length < 1) {
    return '';
  }

  const entry = (day) => ({
    start: day.string_weekday,
    end: day.string_weekday,
    opening_time: day.opening_time,
    closing_time: day.closing_time,
  });
  const groups = days.reduce((g, crr) => {
    const last = g.pop();

    if (crr.opening_time === last.opening_time && crr.closing_time === last.closing_time) {
      last.end = crr.string_weekday;
      g.push(last);
    } else {
      g.push(last);
      g.push(entry(crr));
    }

    return g;
  }, [entry(days[0])]);
  const formatTime = (start, end) => (start === end ? '24hrs.' : `${start.substr(0, 5)} a ${end.substr(0, 5)} hrs.`);

  return groups.map((g) => `${WEEKDAYS[g.start]} a ${WEEKDAYS[g.end]} - ${formatTime(g.opening_time, g.closing_time)}`)
    .join('.');
}

export function previewServiceDays(payload) {
  const serviceDays = [getWeekDay(payload)];

  if (payload.opening_weekends || payload.fulltime_weekends) {
    serviceDays.push(getWeekEnds(payload));
  }

  return serviceDays.join(', ');
}
