import React, { useEffect } from 'react';
import { Container, Col } from 'react-bootstrap';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import EditLockerTabs from '../../components/Locations/EditLockerTabs';
import * as actions from '../../actions/lockers';

export function EditLockerPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useSelector((store) => store.location.data);

  useEffect(() => {
    dispatch(actions.lockerRequest(id));
  }, [id, dispatch]);

  return (
    <Container fluid>
      <Col>
        <h1>LOCKERS</h1>
        <EditLockerTabs generalData={location.name} />
      </Col>
    </Container>
  );
}

export default EditLockerPage;
