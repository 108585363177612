/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

const PALETTE = ['primary-complementary', 'secondary-complementary'];

function getBoxClasses(box, color = 'white') {
  return `tower-box box-${box.size} ${box.is_main ? 'is-main' : ''} ${
    box.available ? '' : 'box-unavailable'
  } bg-${color}`;
}

function getCompaniesPalette(companies) {
  return companies
    .filter((c) => c !== 'public')
    .reduce(
      (palette, company, idx) => ({
        ...palette,
        [company]: PALETTE[idx],
      }),
      {},
    );
}

export default function TowerSet(props) {
  const { towers = [], boxes = [] } = props;
  const byCompany = boxes.reduce(
    (obj, entry) => {
      /* eslint-disable no-param-reassign */
      if (!entry.company) {
        obj.public.boxes[entry.size] = entry.total;
        obj.public.total += entry.total;
      } else if (obj[entry.company.id]) {
        obj[entry.company.id].boxes[entry.size] = entry.total;
        obj[entry.company.id].total += entry.total;
      } else {
        obj[entry.company.id] = {
          name: entry.company.name,
          boxes: {
            [entry.size]: entry.total,
          },
          total: entry.total,
        };
      }

      return obj;
    },
    {
      public: {
        name: 'Red pública',
        boxes: {
          s: 0,
          m: 0,
          l: 0,
          xl: 0,
        },
        total: 0,
      },
    },
  );
  const palette = getCompaniesPalette(Object.keys(byCompany));

  const towerRows = (
    <Row className="tower-set-container justify-content-center" noGutters>
      {towers.map((tower) => (
        <Col key={tower.uuid}>
          <Row className="tower-frame">
            {tower.lockers.map((box) => (
              <Col
                className={getBoxClasses(box, palette[box.company])}
                data-testid="tower-box"
                title={box.uuid}
                key={box.uuid}
              >
                {box.is_main ? 'pantalla' : box.uuid.substr(7, 2)}
              </Col>
            ))}
          </Row>
          <Row noGutters className="tower-resume">
            <span
              className={`dot ${tower.available ? 'bg-success' : 'bg-gray'}`}
              title={tower.available ? 'Disponible' : 'No disponible'}
            >
              &nbsp;
            </span>
            {tower.uuid.substr(4)}
          </Row>
        </Col>
      ))}
    </Row>
  );

  return (
    <Row className="justify-content-center">
      <Col xs={12} className="mb-3">
        {towerRows}
      </Col>
      {Object.entries(byCompany).map(([id, entry]) => (
        <Col xs="auto" key={id}>
          <span className={`color-block bg-${palette[id] || 'white'}`}>&nbsp;&nbsp;</span>
          <b> {entry.name}</b>
          <span> {entry.total} casilleros</span>
        </Col>
      ))}
    </Row>
  );
}

TowerSet.propTypes = {
  towers: PropTypes.arrayOf(PropTypes.object),
  boxes: PropTypes.arrayOf(PropTypes.object),
};
