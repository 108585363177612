import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Card, Button, Row,
} from 'react-bootstrap';
import { Icon } from 'rsuite';
import TowerDnD from './TowerDnD';
import TowerConfigurationForm from '../Forms/TowerConfigurationForm';
import Tower from '../../utils/TowerConfigurator';
import './TowerForm.scss';

export default function TowerForm(props) {
  const {
    onRemove, onMove, index, setMain, tower, onChange, canModify, isMainDisabled,
  } = props;
  const removeTower = () => onRemove(tower.id);
  const handleMain = () => setMain(tower.id);
  const handleChange = (size, count) => onChange(tower.id, size, count);
  const getBoxClasses = (size, isMain) => `tower-box box-${size} ${isMain ? 'is-main' : ''}`;

  return (
    <Col xs={3} className="tower-form__container" data-testid="tower-form">
      <Card>
        <Card.Header className={tower.hasCapacityError() ? 'bg-danger' : ''}>
          {`Torre ${index + 1}`}
          <Button variant="transparent" className="pull-right" onClick={removeTower}>
            <Icon icon="close" size="lg" title="Eliminar" />
          </Button>
        </Card.Header>
        <Card.Body>
          <TowerConfigurationForm
            setMain={handleMain}
            onChange={handleChange}
            tower={tower}
            canModify={canModify}
            isMainDisabled={isMainDisabled}
          />
          {
            canModify && <TowerDnD boxes={tower.boxes} onMove={onMove} id={tower.id} />
          }
          {
            !canModify && (
            <Row className="tower-frame" xs={1} noGutters>
              {
                tower.boxes.map((box) => (
                  <Col className={getBoxClasses(box.size, box.isMain)} data-testid="tower-box" key={box.id}>
                    { box.isMain ? 'pantalla' : box.id }
                  </Col>
                ))
              }
            </Row>
            )
          }
        </Card.Body>
      </Card>
    </Col>
  );
}

TowerForm.propTypes = {
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setMain: PropTypes.func.isRequired,
  tower: PropTypes.instanceOf(Tower),
  canModify: PropTypes.bool,
  isMainDisabled: PropTypes.bool,
};
