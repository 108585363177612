import * as actions from '../constants/actions';

export function lockersRequest() {
  return {
    type: actions.LOCKERS_REQUEST,
    payload: {},
  };
}

export function lockersSucceed({ list }) {
  return {
    type: actions.LOCKERS_REQUEST_SUCCEED,
    payload: {
      list,
    },
  };
}

export function lockersFailed(err) {
  return {
    type: actions.LOCKERS_REQUEST_FAILED,
    payload: {
      message: err.message,
      type: err.type,
    },
  };
}

export function lockerRequest(id, includeStatus = false) {
  return {
    type: actions.LOCKER_REQUEST,
    payload: {
      id,
      includeStatus,
    },
  };
}

export function lockerSucceed(payload) {
  return {
    type: actions.LOCKER_REQUEST_SUCCEED,
    payload,
  };
}

export function lockerUpdateSucceed(payload) {
  return {
    type: actions.LOCKER_UPDATE_REQUEST_SUCCEED,
    payload,
  };
}

export function lockerFailed(err) {
  return {
    type: actions.LOCKER_REQUEST_FAILED,
    payload: {
      message: err.message,
      type: err.type,
    },
  };
}

export function lockerCreateFailed(err) {
  return {
    type: actions.LOCKER_REQUEST_FAILED,
    payload: {
      message: err.message,
      type: err.type,
    },
  };
}

export function createLocker(history, locationData, towers, companyAssignment, networkData) {
  return {
    type: actions.LOCKER_CREATE_REQUEST,
    payload: {
      history,
      locationData,
      towers,
      companyAssignment,
      networkData,
    },
  };
}

export function updateLocker(id, locationData) {
  return {
    type: actions.LOCKER_UPDATE_REQUEST,
    payload: {
      id,
      locationData,
    },
  };
}

export function updateNetwork(id, networkData) {
  return {
    type: actions.LOCKER_UPDATE_NETWORK_REQUEST,
    payload: {
      id,
      networkData,
    },
  };
}

export function updateLockerTowers(id, newTowers, deletedTowers) {
  return {
    type: actions.LOCKER_UPDATE_TOWERS_REQUEST,
    payload: {
      id,
      newTowers,
      deletedTowers,
    },
  };
}

export function updateLockerCompany(id, companyAssignment, currentCompany) {
  return {
    type: actions.LOCKER_UPDATE_COMPANY_REQUEST,
    payload: {
      id,
      companyAssignment,
      currentCompany,
    },
  };
}
