import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Col } from 'react-bootstrap';

export default function ContentModal(props) {
  const {
    // eslint-disable-next-line react/prop-types
    title, subtitle, children, onHide, exitTitle = 'salir', buttons = [], ...modalProps
  } = props;

  return (
    <Modal
      {...modalProps}
      backdrop="static"
      centered
      show
    >
      <Modal.Header className="flex-column align-items-center">
        <Modal.Title>
          {title}
        </Modal.Title>
        { subtitle && <h5 className="text-secondary mt-2">{subtitle}</h5> }
      </Modal.Header>
      <Modal.Body className="text-center">
        <Col md={11} className="mr-auto ml-auto">
          {children}
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-center text-uppercase" variant="link-primary" onClick={() => onHide(-1)}>
          { exitTitle }
        </Button>
        {
          buttons.map((btn, i) => (
            <Button key={btn.key} className="text-center text-uppercase" variant={btn.variant} onClick={() => onHide(i)} disabled={btn.disabled}>
              { btn.title }
            </Button>
          ))
        }
      </Modal.Footer>
    </Modal>
  );
}

ContentModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onHide: PropTypes.func,
  exitTitle: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    variant: PropTypes.string,
    title: PropTypes.string,
    key: PropTypes.string,
  })),
};
