/* eslint-disable no-template-curly-in-string */
export default {
  mixed: {
    required: 'Este campo es requerido',
    notType: 'Este campo es requerido',
  },
  string: {
    min: 'Debe contener al menos ${min} caracteres',
    max: 'Debe contener a lo más ${max} caracteres',
  },
};
