import { useEffect, useState } from 'react';
import axios from '../utils/api';

export default function useAPI(url, method, setResult) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    let isSubscribed = true;

    setIsLoading(true);

    axios({
      url,
      method,
    })
      .then((response) => {
        if (isSubscribed) {
          setError(false);
          setResult(response);
        }
      })
      .catch(() => {
        if (isSubscribed) {
          setErrorMessage('Oops! algo salio mal.');
          setError(true);
        }
      })
      .finally(() => {
        if (isSubscribed) {
          setIsLoading(false);
        }
      });

    // eslint-disable-next-line no-return-assign
    return () => (isSubscribed = false);
  }, [setIsLoading, setError, setErrorMessage, url, method, setResult]);

  return { isError, isLoading, errorMessage };
}
