import authLogic from './auth';
import lockersLogic from './lockers';
import firmwareLogic from './firmware';

const allLogic = [
  ...authLogic,
  ...lockersLogic,
  ...firmwareLogic,
];

export default allLogic;
