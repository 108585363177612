import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import Button from 'react-bootstrap/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputAddress from '../FormInputs/InputAddress';
import HardwareSelect from '../FormInputs/HardwareSelect';
import CompanySelect from '../FormInputs/CompanySelect';
import TimePicker from '../DatePickers/TimePicker';
import Map from '../Map/Map';
import * as titles from '../../constants/titles';

export default function GeneralDataForm(props) {
  const {
    onSubmit,
    initialValues,
    isEdit,
    uuid,
  } = props;
  const formik = useFormik({
    initialValues: {
      address_str: '',
      phone: '',
      address: null,
      parking: false,
      references: '',
      cross_streets: '',
      coords: null,
      hardware_version: '',
      company_host: '',
      internal_name: '',
      opening_weekdays: undefined,
      closing_weekdays: undefined,
      opening_weekends: undefined,
      closing_weekends: undefined,
      fulltime_weekdays: false,
      fulltime_weekends: false,
      ...initialValues,
    },
    validationSchema: Yup.object({
      address_str: Yup.string()
        .min(2)
        .required(),
      phone: Yup.string()
        .matches(/^[\d ()\-+]+$/, titles.INVALID_PHONE),
      address: Yup.object()
        .required(),
      parking: Yup.bool(),
      references: Yup.string()
        .max(35),
      cross_streets: Yup.string()
        .max(35),
      coords: Yup.object()
        .required(),
      hardware_version: Yup.string()
        .required(),
      company_host: Yup.string()
        .required(),
      internal_name: Yup.string()
        .min(3)
        .max(50)
        .required(),
      opening_weekdays: Yup.date()
        .when('fulltime_weekdays', {
          is: false,
          then: Yup.date().required(),
          otherwise: Yup.date().nullable(),
        }),
      closing_weekdays: Yup.date()
        .when('fulltime_weekdays', {
          is: false,
          then: Yup.date().required(),
          otherwise: Yup.date().nullable(),
        }),
      opening_weekends: Yup.date().nullable(),
      closing_weekends: Yup.mixed()
        .when('opening_weekends', {
          is: (value) => value instanceof Date,
          then: Yup.date().required(),
          otherwise: Yup.mixed().oneOf([null], 'No hay hora de apertura'),
        })
        .when('fulltime_weekends', {
          is: true,
          then: Yup.date().nullable(),
        }),
      fulltime_weekends: Yup.bool(),
      fulltime_weekdays: Yup.bool(),
    }),
    onSubmit(values, helpers) {
      helpers.setSubmitting(false);
      onSubmit(values);
    },
  });
  const updateAddress = (value) => {
    formik.setFieldTouched('address', true, false);
    formik.setFieldValue('address', value.address);
    formik.setFieldValue('coords', value.coordinates);
  };

  return (
    <fieldset>
      <Form.Row>
        {
          uuid && (
            <Form.Group controlId="uuid" as={Col} md={2} xs={3}>
              <Form.Label>UUID</Form.Label>
              <Form.Control
                value={uuid}
                readOnly
                plaintext
              />
            </Form.Group>
          )
        }
        <Form.Group controlId="internal_name" as={Col} md={4} xs={9}>
          <Form.Label>Nombre interno</Form.Label>
          <Form.Control
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.internal_name}
            name="internal_name"
            isInvalid={formik.errors.internal_name && formik.touched.internal_name}
          />
          <Form.Text muted>
            { titles.LOCKER_NAME_HELP_TEXT }
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            { formik.errors.internal_name }
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="hardware_version" as={Col} md={4} xs={6}>
          <Form.Label>Modelo de locker</Form.Label>
          <HardwareSelect
            name="hardware_version"
            value={formik.values.hardware_version}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            isInvalid={formik.errors.hardware_version && formik.touched.hardware_version}
          />
          <Form.Control.Feedback type="invalid">
            { formik.errors.hardware_version }
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <h4>Ubicación</h4>
      <hr />
      <Form.Row>
        <Form.Group controlId="company_host" as={Col} md={6} xs={12}>
          <Form.Label>Host</Form.Label>
          <CompanySelect
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.company_host}
            isInvalid={formik.errors.company_host && formik.touched.company_host}
          />
          <Form.Text muted>
            { titles.LOCKER_COMPANY_HELP_TEXT }
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            { formik.errors.company_host }
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="phone" as={Col} md={6} xs={12}>
          <Form.Label>Teléfono</Form.Label>
          <Form.Control
            name="phone"
            value={formik.values.phone}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            isInvalid={formik.errors.phone && formik.touched.phone}
            placeholder="+52 55 1122 3344"
          />
          <Form.Text muted>
            { titles.LOCKER_PHONE_HELP_TEXT }
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            { formik.errors.phone }
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6}>
          <Map
            lat={formik.values.coords?.lat}
            long={formik.values.coords?.long}
          />
        </Col>
        <Col xs={12} md={6}>
          <Form.Row>
            <Form.Group controlId="address_str" as={Col} xs={12}>
              <Form.Label>Dirección</Form.Label>
              <InputAddress
                name="address_str"
                value={formik.values.address_str}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                isInvalid={formik.errors.address_str && formik.touched.address_str}
                isTouched={formik.touched.address_str}
                onValidate={updateAddress}
                isEdit={isEdit}
              />
            </Form.Group>
            <Form.Group controlId="cross_streets" as={Col} xs={6}>
              <Form.Label>Entre calles</Form.Label>
              <Form.Control
                name="cross_streets"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cross_streets}
                isInvalid={formik.errors.cross_streets && formik.touched.cross_streets}
              />
              <Form.Text muted>
                { titles.ADDRESS_CROSS_STREETS_HELP_TEXT }
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                { formik.errors.cross_streets }
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="references" as={Col} xs={6}>
              <Form.Label>Referencias</Form.Label>
              <Form.Control
                name="references"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.references}
                isInvalid={formik.errors.references && formik.touched.references}
              />
              <Form.Text muted>
                { titles.ADDRESS_REFERENCES_HELP_TEXT }
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                { formik.errors.references }
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="parking" as={Col} xs={12}>
              <Form.Check
                size="lg"
                label="Estacionamiento disponible"
                type="switch"
                inline
                custom
                name="parking"
                onChange={formik.handleChange}
                checked={formik.values.parking}
              />
            </Form.Group>
          </Form.Row>
        </Col>
      </Form.Row>
      <h4>Horarios de servicio</h4>
      <hr />
      <Form.Row>
        <Form.Group controlId="weekdaysStart" as={Col} md={4} xs={8}>
          <Form.Label>
            Hora de apertura
            <small> (Lun-Vie)</small>
          </Form.Label>
          <TimePicker
            disabled={formik.values.fulltime_weekdays}
            onChange={(value) => formik.setFieldValue('opening_weekdays', value)}
            onBlur={() => formik.setFieldTouched('opening_weekdays', true)}
            value={formik.values.opening_weekdays}
            isInvalid={formik.touched.opening_weekdays
              && formik.errors.opening_weekdays !== undefined}
          />
          <Form.Control.Feedback
            type="invalid"
            className={formik.errors.opening_weekdays && formik.touched.opening_weekdays ? 'd-block' : ''}
          >
            { formik.errors.opening_weekdays }
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="weekdaysEnd" as={Col} md={4} xs={8}>
          <Form.Label>
            Hora de cierre
            <small> (Lun-Vie)</small>
          </Form.Label>
          <TimePicker
            disabled={formik.values.fulltime_weekdays}
            onChange={(value) => formik.setFieldValue('closing_weekdays', value)}
            onBlur={() => formik.setFieldTouched('closing_weekdays', true)}
            value={formik.values.closing_weekdays}
            isInvalid={formik.errors.closing_weekdays
              && formik.touched.closing_weekdays !== undefined}
          />
          <Form.Control.Feedback
            type="invalid"
            className={formik.errors.closing_weekdays && formik.touched.closing_weekdays ? 'd-block' : ''}
          >
            { formik.errors.closing_weekdays }
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="fulltime_weekdays" as={Col} md={2} xs={12}>
          <FormCheck
            size="lg"
            label="Todo el día"
            type="switch"
            inline
            custom
            name="fulltime_weekdays"
            onChange={formik.handleChange}
            checked={formik.values.fulltime_weekdays}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group controlId="weekendsStart" as={Col} md={4} xs={8}>
          <Form.Label>
            Hora de apertura
            <small> (Sáb-Dom)</small>
          </Form.Label>
          <TimePicker
            disabled={formik.values.fulltime_weekends}
            onChange={(value) => formik.setFieldValue('opening_weekends', value)}
            onBlur={() => formik.setFieldTouched('opening_weekends', true)}
            value={formik.values.opening_weekends}
          />
        </Form.Group>
        <Form.Group controlId="weekendsEnd" as={Col} md={4} xs={8}>
          <Form.Label>
            Hora de cierre
            <small> (Sáb-Dom)</small>
          </Form.Label>
          <TimePicker
            disabled={formik.values.fulltime_weekends}
            onChange={(value) => formik.setFieldValue('closing_weekends', value)}
            onBlur={() => formik.setFieldTouched('closing_weekends', true)}
            value={formik.values.closing_weekends}
            isInvalid={formik.errors.closing_weekends
              && formik.touched.closing_weekends !== undefined}
          />
          <Form.Control.Feedback
            type="invalid"
            className={formik.errors.closing_weekends && formik.touched.closing_weekends ? 'd-block' : ''}
          >
            { formik.errors.closing_weekends }
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="fulltime_weekends" as={Col} md={2} xs={12}>
          <FormCheck
            size="lg"
            label="Todo el día"
            type="switch"
            inline
            custom
            name="fulltime_weekends"
            onChange={formik.handleChange}
            checked={formik.values.fulltime_weekends}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Col className="text-right">
          <Button
            variant="primary"
            size="lg"
            onClick={formik.submitForm}
            disabled={!formik.dirty}
          >
            { isEdit ? 'Guardar' : 'Siguiente' }
          </Button>
        </Col>
      </Form.Row>
    </fieldset>
  );
}

GeneralDataForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape({
    address: PropTypes.instanceOf(Object),
    coords: PropTypes.instanceOf(Object),
    hardware_version: PropTypes.string,
    company_host: PropTypes.string,
    internal_name: PropTypes.string,
  }),
  isEdit: PropTypes.bool,
  uuid: PropTypes.string,
};
