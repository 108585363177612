import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ContentModal from '../Modals/ContentModal';
import { formatLockerLocation } from '../../utils/lockerData';

export default function AddressList(props) {
  const { initialValues, onCancel, onSelect } = props;
  const formik = useFormik({
    initialValues: initialValues.address,
    validationSchema: Yup.object({
      street: Yup.string().min(2).max(60).required(),
      number: Yup.string().min(1).max(20).required(),
      town: Yup.string().min(2).max(50).required(),
      zip_code: Yup.string().min(2).max(6).required(),
      state: Yup.string().min(2).max(50).required(),
    }),
    onSubmit(values) {
      const address = {
        ...initialValues.address,
        ...values,
      };
      onSelect({
        address,
        formatted_address: formatLockerLocation(address),
        coordinates: initialValues.coordinates,
      });
    },
  });
  const onHide = (btnIndex) => {
    if (btnIndex === -1) {
      onCancel();
    } else {
      formik.submitForm();
    }
  };

  return (
    <ContentModal
      title="Dirección"
      exitTitle="Regresar"
      onHide={onHide}
      buttons={[{ key: 'continue', title: 'Aceptar', variant: 'primary' }]}
    >
      <p>Confirma los datos de dirección:</p>
      <Form.Row>
        <Form.Group controlId="street" as={Col} xs={9}>
          <Form.Label>Calle</Form.Label>
          <Form.Control
            name="street"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.street}
            isInvalid={formik.errors.street && formik.touched.street}
          />
          <Form.Control.Feedback type="invalid">
            { formik.errors.street }
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="number" as={Col} xs={3}>
          <Form.Label>Número</Form.Label>
          <Form.Control
            name="number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.number}
            isInvalid={formik.errors.number && formik.touched.number}
          />
          <Form.Control.Feedback type="invalid">
            { formik.errors.number }
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group controlId="town" as={Col} xs={8}>
          <Form.Label>Colonia</Form.Label>
          <Form.Control
            name="town"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.town}
            isInvalid={formik.errors.town && formik.touched.town}
          />
          <Form.Control.Feedback type="invalid">
            { formik.errors.town }
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="zip_code" as={Col} xs={4}>
          <Form.Label>Código Postal</Form.Label>
          <Form.Control
            name="zip_code"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.zip_code}
            isInvalid={formik.errors.zip_code && formik.touched.zip_code}
          />
          <Form.Control.Feedback type="invalid">
            { formik.errors.zip_code }
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group controlId="state" as={Col} xs={12}>
          <Form.Label>Ciudad</Form.Label>
          <Form.Control
            name="state"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.state}
            isInvalid={formik.errors.state && formik.touched.city}
          />
          <Form.Control.Feedback type="invalid">
            { formik.errors.city }
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
    </ContentModal>
  );
}

AddressList.propTypes = {
  initialValues: PropTypes.shape({
    formatted_address: PropTypes.string.isRequired,
    coordinates: PropTypes.shape({
      long: PropTypes.number,
      lat: PropTypes.number,
    }),
    address: PropTypes.shape({
      town: PropTypes.string,
      number: PropTypes.string,
      zip_code: PropTypes.string,
      city: PropTypes.string,
    }),
  }),
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
};
