import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as cst from '../../constants/reservationCodes';
import './CodesList.scss';

export default function CodeList(props) {
  const { tokens } = props;

  const codes = cst.keys.map((key) => (
    <Col key={key}>
      <p className="codes--value">
        {cst.labels[key]}
        <br />
        <b>{tokens[key]}</b>
      </p>
      <img src={tokens[`${key}_qr`]} alt={cst.labels[key]} className="codes--qr" />
    </Col>
  ));

  return <Row lg={5} md={3} xs={1}>{codes}</Row>;
}

CodeList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tokens: PropTypes.instanceOf(Object),
};
