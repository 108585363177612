import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ContentModal from '../Modals/ContentModal';
import ActionDialog from '../MaintenanceDialogs/ActionDialog';
import ReservationDialog from '../MaintenanceDialogs/ReservationDialog';
import * as titles from '../../constants/titles';

const BUTTONS = [
  {
    action: titles.OPEN_BOX,
    title: titles.OPEN_BOX_TITLE,
    disabled() {
      return false;
    },
  },
  {
    action: titles.OPEN_TOWER,
    title: titles.OPEN_TOWER_TITLE,
    disabled() {
      return false;
    },
  },
  {
    action: titles.GET_BOX,
    title: titles.GET_BOX_TITLE,
    disabled(version) {
      return version !== 'v2.0';
    },
  },
  {
    action: titles.GET_IR,
    title: titles.GET_IR_TITLE,
    disabled(version) {
      return version !== 'v2.0';
    },
  },
  {
    action: titles.CREATE_RESERVATION,
    title: titles.CREATE_RESERVATION_TITLE,
    disabled(version, model) {
      return model === 'p2p';
    },
  },
  {
    action: titles.RESET_TOWER,
    title: titles.RESET_TOWER_TITLE,
    disabled(version) {
      return version !== 'v2.0';
    },
  },
  {
    action: titles.RESET_LOCKER,
    title: titles.RESET_LOCKER_TITLE,
    disabled(version) {
      return version !== 'v2.0';
    },
  },
];

export default function AdminDialog(props) {
  const {
    locationId, version, model, name, uuid,
  } = props;

  const [showDialog, setShow] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const resetAction = () => setSelectedAction('');
  const onComplete = () => {
    setSelectedAction('');
    setShow(false);
  };
  const handleClick = () => {
    setShow(true);
  };

  const dialogProps = {
    onComplete,
    onCancel: resetAction,
    subtitle: `LOCKER ${name}, UUID ${uuid}`,
    locationId,
    action: selectedAction,
    key: `action-dialog-${uuid}`,
  };
  let dialog;

  if (showDialog && selectedAction === '') {
    dialog = (
      <ContentModal
        key={`main-dialog-${uuid}`}
        restoreFocus={false}
        title={titles.LOCATION_DIALOG_TITLE}
        subtitle={titles.LOCATION_DIALOG_SUBTITLE}
        onHide={onComplete}
      >
        {BUTTONS.map((cfg) => (
          <Button
            key={cfg.action}
            variant="outline-primary"
            block
            onClick={() => setSelectedAction(cfg.action)}
            disabled={cfg.disabled(version, model)}
          >
            {cfg.title}
          </Button>
        ))}
      </ContentModal>
    );
  } else if (selectedAction === titles.CREATE_RESERVATION) {
    dialog = <ReservationDialog {...dialogProps} />;
  } else if (showDialog) {
    dialog = <ActionDialog {...dialogProps} />;
  }

  return (
    <>
      <Button key={`dialog-button-${uuid}`} onClick={handleClick} variant="secondary" className="mr-0 ml-0">
        &gt;&gt;
      </Button>
      {dialog}
    </>
  );
}

AdminDialog.propTypes = {
  locationId: PropTypes.string.isRequired,
  name: PropTypes.string,
  uuid: PropTypes.string,
  version: PropTypes.string,
  model: PropTypes.string,
};
