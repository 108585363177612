import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as titles from '../../constants/titles';

const IP_REGEX = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export default function NetworkForm(props) {
  const {
    onSubmit,
    onCancel,
    initialValues = {},
    isEdit,
  } = props;
  const formik = useFormik({
    initialValues: {
      ssid: '',
      password: '',
      vpn_ip: '',
      ...initialValues,
    },
    onSubmit(values, helpers) {
      helpers.setSubmitting(false);
      onSubmit(values);
    },
    validationSchema: Yup.object({
      ssid: Yup.string()
        .required(),
      password: Yup.string()
        .required(),
      vpn_ip: Yup.string()
        .matches(IP_REGEX, { message: titles.INVALID_IP }),
    }),
  });

  return (
    <fieldset>
      <Form.Row>
        <Col md={6} xs={12}>
          <Form.Group controlId="ssid">
            <Form.Label>SSID</Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.ssid}
              name="ssid"
              isInvalid={formik.errors.ssid && formik.touched.ssid}
            />
            <Form.Control.Feedback type="invalid">
              { formik.errors.ssid }
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6} xs={12}>
          <Form.Group controlId="password">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              name="password"
              isInvalid={formik.errors.password && formik.touched.password}
            />
            <Form.Control.Feedback type="invalid">
              { formik.errors.password }
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={6} xs={12}>
          <Form.Group controlId="vpnIp">
            <Form.Label>VPN IP</Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.vpn_ip}
              name="vpn_ip"
              isInvalid={formik.errors.vpn_ip && formik.touched.vpn_ip}
            />
            <Form.Control.Feedback type="invalid">
              { formik.errors.vpn_ip }
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        {
          !isEdit && (
            <Col className="text-right">
              <Button variant="light" size="lg" onClick={onCancel}>
                Regresar
              </Button>
            </Col>
          )
        }
        <Col className={isEdit ? 'text-right' : 'text-left'}>
          <Button
            variant="primary"
            size="lg"
            onClick={formik.submitForm}
            disabled={!formik.dirty}
          >
            { isEdit ? 'Guardar' : 'Siguiente' }
          </Button>
        </Col>
      </Form.Row>
    </fieldset>
  );
}

NetworkForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape({
    password: PropTypes.string,
    ssid: PropTypes.string,
    vpn_ip: PropTypes.string,
  }),
};
