import * as titles from '../constants/titles';

export default {
  [titles.CREATE_RESERVATION]: {
    isDanger(message) {
      return message !== 'success';
    },
    toString(message) {
      switch (message) {
        case 'success':
          return 'Reservación exitosa';
        case 'error':
          return 'Reservación falló';

        default:
          return 'Error en lectura';
      }
    },
    textVariant(message) {
      if (message !== 'success') {
        return 'danger';
      }

      return message;
    },
  },
  [titles.OPEN_BOX]: {
    isDanger(message) {
      return message !== 'success';
    },
    toString(message) {
      switch (message) {
        case 'success':
          return 'Apertura de puerta exitosa';
        case 'error':
          return 'Falló en apertura de puerta';
        default:
          return 'Error en lectura';
      }
    },
    textVariant(message) {
      if (message !== 'success') {
        return 'danger';
      }

      return message;
    },
  },
  [titles.OPEN_TOWER]: {
    isDanger(message) {
      return message !== 'success';
    },
    toString(message) {
      switch (message) {
        case 'success':
          return 'Apertura de torre exitosa';
        case 'error':
          return 'Falló en apertura de torre';
        default:
          return 'Error en lectura';
      }
    },
    textVariant(message) {
      if (message !== 'success') {
        return 'danger';
      }

      return message;
    },
  },
  [titles.GET_BOX]: {
    isDanger(message) {
      return message === '';
    },
    toString(message) {
      switch (message) {
        case 'open':
          return 'Abierto';
        case 'closed':
          return 'Cerrado';
        default:
          return 'Error en lectura';
      }
    },
    textVariant(message) {
      switch (message) {
        case 'open':
          return 'success';
        case 'closed':
          return 'info';
        default:
          return 'danger';
      }
    },
  },
  [titles.GET_IR]: {
    isDanger(message) {
      return message === '';
    },
    toString(message) {
      switch (message) {
        case 'occupied':
          return 'Ocupado';
        case 'available':
          return 'Libre';
        default:
          return 'Error en lectura';
      }
    },
    textVariant(message) {
      switch (message) {
        case 'available':
          return 'success';
        case 'occupied':
          return 'info';
        default:
          return 'danger';
      }
    },
  },
  [titles.RESET_TOWER]: {
    isDanger(message) {
      return message !== 'success';
    },
    toString(message) {
      switch (message) {
        case 'success':
          return 'Reseteo exitoso';
        case 'error':
          return 'Reseteo de módulo no completado';
        default:
          return 'Error en lectura de módulo';
      }
    },
    textVariant(message) {
      if (message !== 'success') {
        return 'danger';
      }

      return message;
    },
  },
  [titles.RESET_LOCKER]: {
    isDanger(message) {
      return message !== 'success';
    },
    toString(message) {
      switch (message) {
        case 'success':
          return 'Reseteo exitoso';
        case 'error':
          return 'Reseteo de locker no completado';
        default:
          return 'Error en lectura de locker';
      }
    },
    textVariant(message) {
      if (message !== 'success') {
        return 'danger';
      }

      return message;
    },
  },
};
