import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import { API_RPI_VERSIONS_PATH } from '../../constants/api';
import useAPI from '../../hooks/useAPI';
import { formatDate } from '../../utils/dates';

export default function VersionSelect(props) {
  const { value } = props;
  const [versions, setVersions] = useState([]);
  const saveVersions = useCallback((result) => setVersions(result.releases), [setVersions]);
  const api = useAPI(API_RPI_VERSIONS_PATH, 'get', saveVersions);
  const selectedVersion = versions.find((v) => v.name === value);

  return (
    <>
      <FormControl as="select" custom data-testid="version-form" {...props}>
        <option disabled value="">
          { api.isLoading ? 'Cargando...' : '- Seleccione -' }
        </option>
        {
              versions.map((v) => (
                <option key={v.name} value={v.name}>{v.name}</option>
              ))
        }
      </FormControl>
      <p className="text-muted mt-2">
        <small>
          {
            `${selectedVersion?.description?.split('\n').shift() || ''} - ${formatDate(selectedVersion?.released_at)}`
          }
        </small>
      </p>
    </>
  );
}

VersionSelect.propTypes = {
  value: PropTypes.string,
};
