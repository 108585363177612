import initialState from './initialState';
import * as types from '../../constants/actions';

const lockersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOCKERS_REQUEST:
      return {
        ...state,
        message: '',
        isError: false,
        isLoading: true,
      };
    case types.LOCKERS_REQUEST_SUCCEED:
      return {
        ...state,
        isError: false,
        isLoading: false,
        ...action.payload,
      };
    case types.LOCKERS_REQUEST_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        message: action.payload.message,
      };
    default:
      return {
        ...state,
      };
  }
};

export default lockersReducer;
