import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Marker, useMap } from 'react-leaflet';

export default function CustomMarker(props) {
  const { lat, long } = props;
  const map = useMap();

  useEffect(() => {
    map.flyTo([lat, long], 15);
  }, [lat, long, map]);

  return <Marker position={[lat, long]} />;
}

CustomMarker.propTypes = {
  lat: PropTypes.number.isRequired,
  long: PropTypes.number.isRequired,
};
